import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import marketOne from "../../../assets/mobile/market-one.svg";
import marketTwo from "../../../assets/market-two.svg";
import marketThree from "../../../assets/mobile/market-three.svg";

const MobileOmegoMarket = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="road-trip" style={{padding: '40px 0 0 0'}}>
                {t('translation.omegoMarket')}
            </div>
            <hr/>
            <div className="desci" style={{color: '#284F9D', fontSize: '4.5vw', padding: '10px'}}>
                <Trans i18nKey="translation.mobile.weConnectOurPartners"/>
            </div>
            <div style={{marginTop: '5%'}}>
                <img src={marketOne} style={{display: 'block', margin: '0 auto', width: '60%'}}
                     alt="market"/>
            </div>
            <div className="desci" style={{color: '#284F9D', padding: '10px'}}>
                <Trans i18nKey="translation.mobile.getTheFastestService"/>
            </div>
            <div style={{marginTop: '5%'}}>
                <img src={marketTwo} style={{display: 'block', margin: '0 auto', width: '70%'}}
                     alt="market two"/>
            </div>
            <div className="desci" style={{color: '#284F9D', padding: '10px'}}>
                <Trans i18nKey="translation.mobile.browseThrough"/>
            </div>
            <div style={{marginTop: '5%'}}>
                <img src={marketThree} style={{display: 'block', margin: '0 auto', width: '80%'}}
                     alt="marked tree"/>
            </div>
            <div className="desci" style={{color: '#284F9D', padding: '10px'}}>
                <Trans i18nKey="translation.mobile.utilizeYourResources"/>
            </div>
        </>
    );
};

export default MobileOmegoMarket;