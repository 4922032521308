import React from 'react';
import commIcon from "../../../assets/mobile/comm-icon.svg";
import {Trans, useTranslation} from "react-i18next";
import commMobile from "../../../assets/mobile/comm-mobile.svg";
import clockSolid from "../../../assets/mobile/clock-solid.svg";
import reminders from "../../../assets/mobile/reminders-mobile.svg";

const MobileCommunicator = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="sp">
                <img src={commIcon}/>
                <div>
                    {t('translation.communicator')}
                </div>
            </div>
            <div className="header-text">
                <Trans i18nKey="translation.mobile.dedicatedToCoordinators"/>
            </div>
            <div style={{marginTop: '10%'}}>
                <img src={commMobile} style={{display: 'block', margin: '0 auto', width: '90%'}}/>
            </div>
            <div className="desci-body">
                <Trans i18nKey="translation.mobile.designedToMakeCommunication"/>
            </div>

            <div className="sp">
                <img src={clockSolid}/>
                <div>{t('translation.reminders')}</div>
            </div>
            <div style={{marginTop: '10%'}}>
                <img src={reminders} style={{display: 'block', margin: '0 auto'}} className="reminders-image"/>
            </div>
            <div className="desci scale-in-center" style={{padding: '10px'}}>
                <Trans i18nKey="translation.mobile.whenYouAreBusy"/>
            </div>
        </>
    );
};

export default MobileCommunicator;