class Api {
	constructor() {
		this.base = '';
	}

	setBase(host) {
		this.base = host;
	}

	sendContactRequest(data) {
		return fetch(`${this.base}/contact-request`, {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(data)
		});
	}
}

const api = new Api();

export default api;
