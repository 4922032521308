import React, {Component} from 'react';
import "./MobileView.scss";
import 'antd/dist/antd.css';
import cloud from "../../assets/mobile/cloud.svg";
import {message, Select} from 'antd';
import envelope from "../../assets/mobile/envelope-solid.svg";
import TextField from "@material-ui/core/TextField/TextField";
import twitter from "../../assets/twitter.svg";
import mediumm from "../../assets/medium.svg";
import linked from "../../assets/linked.svg";
import * as sha1 from "sha1";
import Button from "@material-ui/core/Button";
import MobileFooter from "./MobileFooter/MobileFooter";
import {Formik} from "formik";
import moment from "moment";
import OnSubmitValidationError from "../OmegoPage/ContactForm/OnSubmitValidationError/OnSubmitValidationError";
import api from '../../core/services/api';
import {Trans, withTranslation} from 'react-i18next';
import MobileHeader from "./MobileHeader/MobileHeader";
import MobileCalculators from "./MobileCalculators/MobileCalculators";
import MobileShiftPlan from "./MobileShiftPlan/MobileShiftPlan";
import MobileOmegoApp from "./MobileOmegoApp/MobileOmegoApp";
import MobileCommunicator from "./MobileCommunicator/MobileCommunicator";
import MobileOperationView from "./MobileOperationView/MobileOperationView";
import MobileBeAware from "./MobileBeAware/MobileBeAware";
import MobileOmegoMarket from "./MobileOmegoMarket/MobileOmegoMarket";
import MobileTrustedBy from "./MobileTrustedBy/MobileTrustedBy";
import MobileCompanyFooter from "./MobileCompanyFooter/MobileCompanyFooter";


const {Option} = Select;

class MobileView extends Component {
    constructor(props) {
        super(props);
        this.header = React.createRef();
        this.state = {
            language: 'en',
        }
    }

    componentDidMount() {
        this.setLanguage();
    }

    setLanguage = () => {
        if (localStorage.getItem('language') == null) {
            this.setState({language: 'en'});
            localStorage.setItem('language', 'en');
            return;
        }

        this.setState({language: localStorage.getItem('language')});
    };

    clearForm = (values, {resetForm}) => {

        let newDate = values.date != null ? `${moment(values.date).format("HH:mm")}` : '';
        let newFromTime = values.fromTime != null ? `${moment(values.fromTime).format("HH:mm")}` : '';
        let newToTime = values.toTime != null ? `${moment(values.toTime).format("HH:mm")}` : '';

        const data = {
            name: values.name,
            company: values.companyName,
            email: values.email,
            size: values.companySize,
            date: values.date != null ? `${moment(values.date).format("HH:mm")}` : '',
            fromTime: values.fromTime != null ? `${moment(values.fromTime).format("HH:mm")}` : '',
            toTime: values.toTime != null ? `${moment(values.toTime).format("HH:mm")}` : '',
            key: `${sha1(values.name + `${values.email}` + values.companyName + values.companySize + newDate + newFromTime + newToTime + '9230093468a9d7bdf3c33c1f03a2d67c1bfc621aca42c39427eb1a2f39ca0b4f')}`
        };

        api.sendContactRequest(data)
            .then(resp => {
                if (this.state.language === 'en') {
                    message.success('Thank you, we will be in touch.');
                } else {
                    message.success('Takk, vi kontakter deg snart.');
                }

                resetForm();
            }).catch(error => {
            if (this.state.language === 'en') {
                message.warning('Ups.. Something went wrong')
            } else {
                message.warning('Ups.. Noe gikk galt.')
            }
        })

    };

    render() {

        const {t} = this.props;

        return (
            <div className="mobile-container">
                <MobileHeader/>
                <div className="savings" id="goto">
                    <div className="savings-header">
                        <Trans i18nKey="translation.mobile.checkSavings"/>
                    </div>
                    <hr/>
                    <img src={cloud} alt="cloud" className="cloud"/>
                </div>

                <MobileCalculators/>
                <div className="text">
                    <div className="road-trip">
                        <Trans i18nKey="translation.mobile.startRoad"/>
                    </div>
                    <hr/>
                    <MobileShiftPlan/>
                    <MobileOmegoApp/>
                    <MobileCommunicator/>
                    <MobileOperationView/>
                    <MobileBeAware/>
                    <div className="back-mobile">
                        <MobileOmegoMarket/>
                        <MobileTrustedBy/>
                        <Formik
                            initialValues={{
                                name: '',
                                companyName: '',
                                email: '',
                                companySize: '',
                                date: null,
                                fromTime: null,
                                toTime: null,
                            }}
                            validate={values => {
                                let errors = {};

                                if (values.name === '') {
                                    errors.name = (
                                        <span className="error-message">
                                        This field is required!
                                    </span>
                                    );
                                }

                                if (values.companySize === '') {
                                    errors.companySize = (
                                        <span className="error-message">
                                        This field is required!
                                    </span>
                                    );
                                }

                                if (values.companyName === '') {
                                    errors.companyName = (
                                        <span className="error-message">
                                        This field is required!
                                    </span>
                                    );
                                }

                                if (moment(values.fromTime).isAfter(moment(values.toTime))) {
                                    errors.time = (
                                        <span className="error-message">
                                        Something is wrong with picked time.
                                    </span>
                                    );
                                }
                                return errors;
                            }}
                            onSubmit={this.clearForm}
                        >
                            {({
                                  values,
                                  errors,
                                  handleSubmit,
                                  setFieldValue,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div id="form">
                                        {this.state.language === 'en' ? <div className="contacts">
                                            <img src={envelope} alt="envelope"/>
                                            <div>Contact Us</div>
                                        </div> : <div className="contacts">
                                            <img src={envelope} alt="envelope"/>
                                            <div style={{fontSize: '5.6vw'}}><Trans
                                                i18nKey="translation.mobile.contactUs"/></div>
                                        </div>}
                                    </div>
                                    <div className="desci">
                                        <Trans i18nKey="translation.mobile.pleaseFillIn"/>
                                    </div>
                                    <div className="inputs">
                                        <TextField
                                            id="filled-name"
                                            label={t('translation.yourName')}
                                            name="name"
                                            value={values.name}
                                            onChange={e => setFieldValue('name', e.target.value)}
                                            margin="normal"
                                            variant="filled"
                                        />
                                        <OnSubmitValidationError error={errors.name}/>
                                        <TextField
                                            id="filled-name"
                                            label={t('translation.companyName')}
                                            name="companyName"
                                            value={values.companyName}
                                            onChange={e => setFieldValue('companyName', e.target.value)}
                                            margin="normal"
                                            variant="filled"
                                        />
                                        <OnSubmitValidationError error={errors.companyName}/>
                                        <TextField
                                            id="filled-name"
                                            label={t('translation.email')}
                                            name="email"
                                            htmlname="email"
                                            value={values.email}
                                            onChange={e => setFieldValue("email", e.target.value)}
                                            margin="normal"
                                            type="email"
                                            variant="filled"
                                            required
                                        />
                                        <>
                                            <Select
                                                value={values.companySize === '' ? t('translation.companySize') : values.companySize}
                                                name="companySize"
                                                htmlname="companySize"
                                                onChange={e => setFieldValue("companySize", e)}
                                                className="company-size-select-mobile"
                                            >
                                                <Option value="1-20">1-20 {t('translation.employees')}</Option>
                                                <Option value="21-100">21-100 {t('translation.employees')}</Option>
                                                <Option value="101-500">101-500 {t('translation.employees')}</Option>
                                                <Option value="501-1000">501-1000 {t('translation.employees')}</Option>
                                                <Option value="1000+">1000+ {t('translation.employees')}</Option>
                                            </Select>
                                        </>
                                        <OnSubmitValidationError error={errors.companySize}/>
                                        <Button className="submit-button-mobile" type="submit">
                                            {t('translation.submit')}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                    <MobileCompanyFooter/>
                    <div className="media">
                        <img src={twitter} alt="twitter"/>
                        <img src={mediumm} alt="medium"/>
                        <a href="https://www.linkedin.com/company/omegofleet">
                            <img src={linked} alt="linkedin"/>
                        </a>
                    </div>
                    <MobileFooter/>
                </div>
            </div>
        )
    }
}

export default withTranslation()(MobileView);
