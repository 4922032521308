import React, {Component} from 'react';
import header from "../../assets/omego-header.svg";
import "./OmegoPage.scss";
import 'antd/dist/antd.css'
import {Tooltip} from 'antd';
import language from "../../assets/language.svg";
import partTwo from "../../assets/part-two.svg";
import LazyLoad from 'react-lazyload';
import "./animations.scss";
import schedule from "../../assets/schedule.svg";
import expBack from "../../assets/exp-back.svg";
import fastDelivery from "../../assets/fast-delivery.svg";
import browse from "../../assets/browse.svg";
import gray from "../../assets/gray.svg";
import utilize from "../../assets/change.svg";
import bottom from "../../assets/bottom.svg";
import trustedBy from "../../assets/partners.svg";
import elipse from "../../assets/elipse.svg";
import ContactForm from "./ContactForm/ContactForm";
import ContactUs from "./ContactUs/ContactUs";
import CookieMessage from "../CookieMessage/CookieMessage";
import Calculator from "./Calculator/Calculator";
import ImageSlider from "./ImageSlider/ImageSlider";
import StartRoad from "./StartRoad/StartRoad";
import Phone from "./Phone/Phone";
import Alerts from "./Alerts/Alerts";
import SiriWave from "./SiriWave/SiriWave";
import Communicator from "./Communicator/Communicator";
import Reminders from "./Reminders/Reminders";
import OperationView from "./OperationView/OperationView";
import OmegoHeader from "../OmegoHeader/OmegoHeader";
import Precize from "./Precize/Precize";
import {Trans, withTranslation} from 'react-i18next';

class OmegoPage extends Component {
    constructor(props) {
        super(props);
        this.header = React.createRef();
        this.calculator = React.createRef();
        this.bottomPage = React.createRef();
        this.state = {
            language: 'en',
            taskSelected: 2,
            fuel: 0,
            co: 0,
            clock: 0,
            kilometers: 0,
            position: 0,
            moveDot: true,
            firstCloud: false,
            secondCloud: false,
            box: 350
        }
    }

    updateSizes = () => {
        if (this.header.current !== null) {
            const headerHeight = this.header.current.clientHeight;
            const calc = this.calculator.current.clientHeight;
            const bottom = this.bottomPage.current.clientHeight;
            document.getElementById('calculator').style.height = `${calc}px`;
            document.querySelector('.omego-header').style.height = `${headerHeight}px`;
            document.getElementById('bottom').style.height = `${bottom}px`;
        }

    };

    componentDidMount() {
        window.addEventListener("resize", this.updateSizes);
        window.addEventListener('scroll', this.handleScrollToElement);
        this.updateSizes();
        this.setLanguage();
    }

    setLanguage = () => {
        if (localStorage.getItem('language') !== null) {
            this.setState({language: localStorage.getItem('language')});
        }
    };

    handleChange = (value) => {
        this.setState({language: value});
        localStorage.setItem('language', value);
    };

    handleScrollToElement = () => {
        if (document.getElementById('container') !== undefined && document.getElementById('container') !== null) {
            if (window.pageYOffset > (document.getElementById('container').offsetHeight + document.getElementById('container').offsetTop) - (document.getElementById('container').offsetHeight * 0.1)) {
                return;
            }
            const a = document.getElementById('dot');
            if (window.pageYOffset === document.getElementById('container').offsetTop) {
                this.setState({position: 0})
            }
            if (window.pageYOffset > document.getElementById('container').offsetTop) {
                if (this.state.position !== 0 && window.pageYOffset < this.state.position) {
                    const top = Math.abs(document.getElementById('container').offsetTop - window.pageYOffset) + (document.getElementById('container').offsetHeight * 0.1);
                    a.style.top = top.toString() + 'px';
                    this.setState({position: window.pageYOffset});
                    return;
                }

                const top = Math.abs(document.getElementById('container').offsetTop - window.pageYOffset) + (document.getElementById('container').offsetHeight * 0.1);
                a.style.top = top.toString() + 'px';
                this.setState({position: window.pageYOffset});
            }
        }
    };

    changeTaskSelected = (value) => {
        this.setState({taskSelected: value})
    };

    viewElementContact = () => {
        const element = document.getElementById("form");
        element.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
    };

    render() {

        const {t} = this.props;

        return (
            <div style={{width: "100%"}}>
                <ContactUs language={this.state.language}/>

                <img src={header} ref={this.header} onLoad={this.updateSizes} alt="header"/>
                <img src={partTwo} alt="white" onLoad={this.updateSizes} id="calculator"/>

                <div className="header-container">
                    <div className="container">
                        <OmegoHeader language={this.state.language} handleChange={this.handleChange}/>

                        <div ref={this.calculator} id="calc">
                            <div className="savings-proposal">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <h3>{t('translation.checkSavings')}</h3>
                                    <Tooltip
                                        title={t('translation.estimationReal')}><span
                                        className="star"> <span>*</span></span></Tooltip></div>
                            </div>

                            <div className="exact"
                                 onClick={this.viewElementContact}>{t('translation.getExactValues')}</div>


                            <Calculator language={this.state.language}/>
                            <ImageSlider taskSelected={this.state.taskSelected} language={this.state.language}
                                         currentScreenWidth={this.props.currentScreenWidth}
                                         changeTaskSelected={this.changeTaskSelected}/>
                            <div className="line-container" id="container">
                                <div className="follow-line" id="line"/>
                                <div className="follow-dot" id="dot"/>
                                <StartRoad language={this.state.language}/>

                                <div className="schedule">
                                    <img src={schedule} alt="schedule"/>
                                </div>

                                <Phone language={this.state.language}/>

                                <Alerts language={this.state.language}/>

                                <SiriWave language={this.state.language}/>

                                <Communicator language={this.state.language}/>

                                <Reminders language={this.state.language}/>

                                <OperationView language={this.state.language}/>
                            </div>
                        </div>
                    </div>
                    <div className="container-two">
                        <img src={gray} className="gray"/>
                        <div className="container-element">
                            <Precize language={this.state.language}/>
                            <div className="experiences">
                                <div className="back-image-container">
                                    <img src={expBack} className="back-image"/>
                                    <div className="dot-one blink">
                                    </div>
                                    <div className="dot-two blink-1">
                                    </div>
                                    <div className="dot-three blink-2">
                                    </div>
                                    <div className="dot-four blink-3">
                                    </div>
                                    <div className="dot-five blink-4">
                                    </div>
                                    <div className="dot-six blink-1">
                                    </div>
                                    <div className="dot-seven blink-2">
                                    </div>
                                    <div className="dot-eight blink-4">
                                    </div>
                                    <div className="dot-nine blink">
                                    </div>
                                    <div className="dot-ten blink-2">
                                    </div>
                                    <div className="dot-eleven blink-3">
                                    </div>
                                    <div className="dot-twelve blink-1">
                                    </div>
                                </div>

                                <div className="body">
                                    <LazyLoad offset={-200} placeholder={<div className="experiences-header"
                                                                              style={{color: "transparent"}}>
                                        {t('translation.creatingNewExperiences')}
                                    </div>}>
                                        <div className="experiences-header slide-in-bottom">
                                            {t('translation.creatingNewExperiences')}
                                        </div>
                                    </LazyLoad>
                                    <div className="first-text">
                                        <LazyLoad offset={-200}
                                                  placeholder={<div className="text" style={{color: "transparent"}}>
                                                      <div className="text-header"
                                                           style={{color: "transparent"}}>
                                                          {t('translation.omegoMarket')}
                                                      </div>
                                                      <div className="text-body" style={{color: "transparent"}}>
                                                          <Trans i18nKey="translation.weConnectOurPartners"/>
                                                      </div>
                                                  </div>}>
                                            <div className="text slide-in-left">
                                                <div className="text-header">
                                                    {t('translation.omegoMarket')}
                                                </div>

                                                <div className="text-body">
                                                    <Trans i18nKey="translation.weConnectOurPartners"/>
                                                </div>
                                            </div>
                                        </LazyLoad>
                                    </div>
                                    <div className="second-text">
                                        <img src={fastDelivery} alt="fast-delivery"/>
                                        <LazyLoad offset={-200} placeholder={<div style={{width: "30%"}}></div>}>
                                            <div className="text slide-in-right">
                                                <Trans i18nKey="translation.getTheFastestService"/>
                                            </div>
                                        </LazyLoad>
                                    </div>
                                    <div className="third-text">
                                        <LazyLoad offset={-200} placeholder={<div style={{width: '50%'}}/>}>
                                            <div className="text slide-in-left">
                                                <Trans i18nKey="translation.browseThrough"/>
                                            </div>
                                        </LazyLoad>
                                        <img src={browse} alt="browse" style={{width: '50%'}}/>
                                    </div>
                                    <div className="fourth-text">
                                        <img src={utilize} alt="utilize"/>
                                        <LazyLoad offset={-200} placeholder={<div style={{width: "29%"}}></div>}>
                                            <div className="text slide-in-right">
                                                <Trans i18nKey="translation.utilizeYourResources"/>
                                            </div>
                                        </LazyLoad>
                                    </div>
                                    <div className="bottom" id="bottom">
                                        <img src={bottom} alt="bottom-background" className="bottom-background"
                                             onLoad={this.updateSizes} ref={this.bottomPage}/>
                                        <div className="bottom-body">
                                            <div className="bottom-header slide-in-bottom">
                                                {t('translation.trustedBy')}
                                            </div>
                                            <div className="trusted slide-in-bottom">
                                                <img src={trustedBy} alt="trusted-by"/>
                                            </div>
                                            <div className="quote">
                                                <img src={elipse} alt="eclipse"/>
                                                <div className="quote-body">
                                                    <LazyLoad offset={-200}>
                                                        <Trans i18nKey="translation.weAreImpressed" style={{fontSize: '8vw'}}/>
                                                        <div className="sign slide-in-left">
                                                            Jon Kåre Stene
                                                        </div>
                                                        <div className="desc slide-in-left">
                                                            <Trans i18nKey="translation.partnerChef"/>
                                                        </div>
                                                    </LazyLoad>
                                                </div>
                                            </div>

                                        </div>
                                        <ContactForm language={this.state.language}/>
                                        <CookieMessage/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OmegoPage);
