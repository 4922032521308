import React, {Component} from 'react';
import MobilePrivacyPolicy from "../MobileView/MobilePrivacyPolicy/MobilePrivacyPolicy";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

class ViewPrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };

        window.scrollTo(0, 0);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidMount() {

    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    render() {
        const {width} = this.state;
        const isMobile = width <= 500;

        // return (
        //     <OmegoPage currentScreenWidth={width}/>
        // )

        if (isMobile) {
            return (
                <MobilePrivacyPolicy currentScreenWidth={width}/>
            )
        } else {
            return (
                <PrivacyPolicy currentScreenWidth={width}/>
            )
        }


    }
}

export default ViewPrivacyPolicy;