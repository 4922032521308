export const formatTooltipRight = (value) => {
    switch (value) {
        case 1:
            return 5000;
        case 2:
            return 10000;
        case 3:
            return 15000;
        case 4:
            return 20000;
        case 5:
            return 25000;
        case 6:
            return 30000;
        case 7:
            return 35000;
        case 8:
            return 40000;
        case 9:
            return 45000;
        case 10:
            return 50000;
        case 11:
            return 55000;
        case 12:
            return 60000;
        case 13:
            return 65000;
        case 14:
            return 70000;
        case 15:
            return 75000;
    }
};