import React from 'react';
import "./MobileCompanyFooter.scss";
import norwayFlag from "../../../assets/Footer/norwayFlag.svg";
import polishFlag from "../../../assets/Footer/polishFlag.svg";
import usFlag from "../../../assets/Footer/usFlag.svg";
import {NavLink} from "react-router-dom";

const MobileCompanyFooter = () => {
    return (
        <div className="company-mobile-footer">
            <div>
                <h2 className="office-location-header-mobile">Office locations</h2>
                <div className="country-container">
                    <img src={norwayFlag} alt="norway flag"/> <span className="country-header">Norway</span>
                    <p className="mobile-footer-text">Brysnveien 3,<br/> 0067 Oslo</p>
                </div>

                <div className="country-container">
                    <img src={polishFlag} alt="polish flag"/> <span className="country-header">Poland</span>
                    <p className="mobile-footer-text">ul. Grabiszyńska 214/7,</p>
                    <p className="mobile-footer-text">53-235, Wrocław</p>
                </div>

                <div className="country-container">
                    <img src={usFlag} alt="usa flag"/> <span className="country-header">United States</span>
                    <p className="mobile-footer-text">470 Ramona St. Palo Alto,</p>
                    <p className="mobile-footer-text">94301 Palo Alto CA</p>
                </div>
            </div>

            <div>
                <h2 className="office-location-header-mobile">Informations</h2>
                <NavLink to="/site/terms">
                    <p>
                        Terms and Conditions
                    </p>
                </NavLink>
                <NavLink to="/site/privacy-policy">
                    <p>
                        Privacy Policy
                    </p>
                </NavLink>
            </div>

            <div>
                <h2 className="office-location-header-mobile">Contact us</h2>
                <p>
                    <a href="mailto:contact@omegofleet.com">contact@omegofleet.com</a>
                </p>
                <p>
                    <a href="tel:+4794478464">+47 944 784 64</a>
                </p>

            </div>

        </div>
    );
};

export default MobileCompanyFooter;