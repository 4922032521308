import React from 'react';
import LazyLoad from "react-lazyload";
import communicatorView from "../../../assets/change3.svg";
import detailsImage from "../../../assets/details-image.png";
import detailsText from "../../../assets/details-text.svg";
import "./Communicator.scss";
import {Trans, useTranslation} from 'react-i18next';

const Communicator = props => {
    const {t} = useTranslation();

    return (
        <div className="communicator">

            <div className="communicator-header">
                {t('translation.communicator')}
            </div>
            <div className="communicator-body">
                <LazyLoad offset={-200} placeholder={<div style={{width: "30%"}}></div>}>
                    <div className="desc slide-in-left">
                        <div className="desc-header">
                            <Trans i18nKey="translation.dedicatedToCoordinators"/>
                        </div>
                        <div className="desc-body">
                            <Trans i18nKey="translation.designedToMakeCommunication"/>
                        </div>
                    </div>
                </LazyLoad>
                <div className="image">
                    <img src={communicatorView} alt="communicator-view"/>
                </div>
                <div className="details">
                    <img src={detailsImage} alt="details-image"/>
                    <img src={detailsText} alt="details-text"
                         style={{position: 'relative', top: 20, left: 5}}/>
                </div>
            </div>
        </div>
    );
};

export default Communicator;

