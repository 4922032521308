import React, {Component} from 'react';
import omegoHeader from "../../assets/omego-fleet.svg";
import {Select} from "antd";
import "./TermsHeader.scss";
import {NavLink} from "react-router-dom";

const {Option} = Select;


class TermsHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'English',
        }
    }

    render() {
        return (
            <div className="terms-header">
                <div>
                    <NavLink to="/">
                        <img src={omegoHeader} alt="omego-fleet" className="omego-logo"/>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default TermsHeader;