import React from 'react';
import Siriwave from "react-siriwave";
import LazyLoad from "react-lazyload";
import "./SiriWave.scss";
import {Trans, useTranslation} from 'react-i18next';

const SiriWave = props => {

    const {t} = useTranslation();

    return (
        <div className="siri-container">
            <div className="container">
                <div className="siri">
                    <Siriwave style={"ios9"} amplitude={3}/>
                </div>
                <LazyLoad offset={-200} placeholder={<div style={{height: '200px'}}/>}>
                    <div className="voice slide-in-bottom">
                        <div className="voice-header">
                            {t('translation.voice')}
                        </div>
                        <div className="voice-body">
                            <Trans i18nKey="translation.allowsDriversToConcentrate"/>
                        </div>
                    </div>
                    <div className="desc slide-in-bottom">
                        <Trans i18nKey="translation.theAppAllows"/>
                    </div>
                </LazyLoad>
            </div>
        </div>
    );
};

export default SiriWave;