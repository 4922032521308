import React from 'react';
import precizeIcon from "../../../assets/mobile/precize-icon.svg";
import precizeMobile from "../../../assets/mobile/precize-mobile.svg";
import {Trans, useTranslation} from "react-i18next";
import optimizeIcon from "../../../assets/mobile/optimize-icon.svg";
import optimize from "../../../assets/mobile/optimize-mobile.svg";

const MobileBeAware = () => {
    const {t} = useTranslation();
    return (
        <>
            <div className="sp">
                <img src={precizeIcon} alt="precize"/>
                <div>
                    {t('translation.bePrecize')}
                </div>
            </div>
            <div style={{marginTop: '10%'}}>
                <img src={precizeMobile} style={{display: 'block', margin: '0 auto'}}
                     className="reminders-image"/>
            </div>
            <div style={{marginBottom: "30px"}} className="desci">
                <Trans i18nKey="translation.mobile.addDeliveryPoints"/>
            </div>
            <div className="desci">
                <Trans i18nKey="translation.mobile.ifTheSystem"/>
            </div>
            <div className="sp">
                <img src={optimizeIcon} alt="optimize"/>
                <div style={{fontSize: '5.6vw'}}>
                    {t('translation.optimizeDailyWork')}
                </div>
            </div>
            <div style={{marginTop: '10%'}}>
                <img src={optimize} style={{display: 'block', margin: '0 auto'}}/>
            </div>
            <div className="desci" style={{margin: '20px'}}>
                <Trans i18nKey="translation.mobile.adjustShifts"/>
            </div>
        </>
    );
};

export default MobileBeAware;