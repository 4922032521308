import React, {useRef} from 'react';
import "./MobileHeader.scss";
import header from "../../../assets/mobile/mobile-header.svg";
import mobileName from "../../../assets/mobile/mobile-name.svg";
import english from "../../../assets/mobile/english.svg";
import norway from "../../../assets/mobile/norway.svg";
import polish from "../../../assets/mobile/polish-flag.svg";
import {Select} from "antd";
import {Trans, useTranslation} from "react-i18next";
import bus from "../../../assets/bus.svg";
import bike from "../../../assets/mobile/mobile-bike.svg";
import messageSvg from "../../../assets/mobile/message.svg";
import i18n from "i18next";

const {Option} = Select;

const MobileHeader = props => {
    const {t} = useTranslation();
    const headerRef = useRef(null)

    const changeLanguage = value => {
        localStorage.setItem('language', value);
        i18n.changeLanguage(value);
    };

    const viewElement = () => {
        const element = document.getElementById("goto");
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    };

    const viewElementContact = () => {
        const element = document.getElementById("form");
        element.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
    };

    return (
        <div className="mobile-header">
            <img src={header} alt="header" ref={headerRef}/>
            <div className="header-container" id="header">
                <div className="name">
                    <img src={mobileName} alt="name" className="name-logo"/>
                    <div className="language-container">
                        {localStorage.getItem('language') === 'en' ?
                            <img src={english} alt="english"
                                 className="language-logo"/> : localStorage.getItem('language') === 'no' ?
                                <img src={norway} alt="norway" className="language-logo"/> :
                                <img src={polish} alt="poland" className="language-logo"/>}
                        <Select value={localStorage.getItem('language')} onChange={changeLanguage}>
                            <Option value="en">English</Option>
                            <Option value="no">Norsk</Option>
                            <Option value="pl">Polski</Option>
                        </Select>
                    </div>
                </div>
                <h1 className="virtual">
                    <Trans i18nKey="translation.mobile.virtualCoordinator"/>
                </h1>
                <div className="intelligent">
                    <Trans i18nKey="translation.inteligentToolbox"/>
                </div>
                <img src={bus} alt="bus" className="bus"/>
                <div className="button" onClick={viewElement}>
                    {t('translation.readMore')}
                </div>
                <div className="header-bottom">
                    <img src={bike} alt="bike" className="bike"/>
                    <img src={messageSvg} alt="message" className="message" onClick={viewElementContact}/>
                </div>
            </div>
        </div>
    );
}

export default MobileHeader;