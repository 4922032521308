import React, {Component} from 'react';
import "./CookieMessage.scss";
import {Button} from "antd";

class CookieMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accepted: false,
        };

        this.key = 'persist:omego-fleet-landing-page-cookie';
    }

    componentDidMount() {
        const accepted = JSON.parse(localStorage.getItem(this.key));

        if (accepted) {
            this.setState({accepted: true});
        }
    }

    handleCookie = () => {
        this.setState({accepted: true});
        localStorage.setItem(this.key, JSON.stringify(true));
    };

    render() {
        return (
            <>
                {!this.state.accepted ? (<div className="cookie-message">
                    <p className="cookie-text">
                        We use cookies on our websites to help us offer you the best online experience. By continuing to
                        use
                        our website, you are agreeing to our use of cookies. Alternatively, you can manage them in your
                        browser settings.
                    </p>
                    <Button className="accept-button" type="ghost" onClick={this.handleCookie}>I understand</Button>
                </div>) : null}
            </>
        );
    }
}

export default CookieMessage;