export const formatTooltip = (value) => {
    switch (value) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        case 6:
            return 10;
        case 7:
            return 15;
        case 8:
            return 20;
        case 9:
            return 25;
        case 10:
            return 30;
        case 11:
            return 50;
        case 12:
            return 75;
        case 13:
            return 100;
        case 14:
            return 125;
        case 15:
            return 150;
        default:
            return 1;
    }
};