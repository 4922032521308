import React from 'react';
import {connect} from 'formik';

function OnSubmitValidationError(props) {
    const {formik} = props;

    if (formik.submitCount > 0 && !formik.isSubmitting && props.error != null) {
        return props.error;
    }

    return <></>;
}

export default connect(OnSubmitValidationError);
