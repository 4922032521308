import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import team from "../../../assets/team.png";
import "./ContactForm.scss";
import * as sha1 from "sha1";
import Footer from "../Footer/Footer";
import {message} from "antd";
import moment from "moment";
import SuccesMesage from "../SuccessMessage/SuccesMesage";
import {Formik} from "formik";
import {Select} from "formik-antd";
import OnSubmitValidationError from "./OnSubmitValidationError/OnSubmitValidationError";
import api from '../../../core/services/api';
import {Trans, withTranslation} from "react-i18next";

const {Option} = Select;

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
        }
    }

    closeModal = () => this.setState({modalVisible: false});


    sendAndClearForm = (values, {resetForm}) => {

        let newDate = values.date != null ? `${moment(values.date).format("HH:mm")}` : '';
        let newFromTime = values.fromTime != null ? `${moment(values.fromTime).format("HH:mm")}` : '';
        let newToTime = values.toTime != null ? `${moment(values.toTime).format("HH:mm")}` : '';

        const data = {
            name: values.name,
            company: values.companyName,
            email: values.email,
            size: values.companySize,
            date: values.date != null ? `${moment(values.date).format("HH:mm")}` : '',
            fromTime: values.fromTime != null ? `${moment(values.fromTime).format("HH:mm")}` : '',
            toTime: values.toTime != null ? `${moment(values.toTime).format("HH:mm")}` : '',
            key: `${sha1(values.name + `${values.email}` + values.companyName + values.companySize + newDate + newFromTime + newToTime + '9230093468a9d7bdf3c33c1f03a2d67c1bfc621aca42c39427eb1a2f39ca0b4f')}`
        };

        api.sendContactRequest(data)
            .then(resp => {
                if (this.props.language === 'en') {
                    this.setState({modalVisible: true})
                }

                resetForm();
            })
            .catch(error => {
                if (this.props.language === 'en') {
                    message.warning('Ups.. Something went wrong')
                } else {
                    message.warning('Ups.. Noe gikk galt.')
                }
            });
    };

    disabledDate(current) {
        return current && current < moment().subtract(1, 'day').endOf('day');
    }

    render() {
        const {t} = this.props;

        return (
            <div className="form-container" id="form">
                <div className="container">
                    <Formik
                        initialValues={{
                            name: '',
                            companyName: '',
                            email: '',
                            companySize: '',
                            date: null,
                            fromTime: null,
                            toTime: null,
                        }}
                        validate={values => {
                            let errors = {};

                            if (values.name === '') {
                                errors.name = (
                                    <span className="error-message">
                                        This field is required!
                                    </span>
                                );
                            }

                            if (values.companySize === '') {
                                errors.companySize = (
                                    <span className="error-message">
                                        This field is required!
                                    </span>
                                );
                            }

                            if (values.companyName === '') {
                                errors.companyName = (
                                    <span className="error-message">
                                        This field is required!
                                    </span>
                                );
                            }

                            if (moment(values.fromTime).isAfter(moment(values.toTime))) {
                                errors.time = (
                                    <span className="error-message">
                                        Something is wrong with picked time.
                                    </span>
                                );
                            }
                            return errors;
                        }}
                        onSubmit={this.sendAndClearForm}
                    >
                        {({
                              values,
                              errors,
                              handleSubmit,
                              handleReset,
                              resetForm,
                              setFieldValue,
                              isSubmitting
                          }) => (
                            <form onSubmit={handleSubmit}
                                  style={{width: '50%'}}>
                                <div className="form">
                                    <div className="form-header">
                                        {t('translation.bookMeeting')}
                                    </div>
                                    <div className="form-body">
                                        <div className="info">
                                            <Trans i18nKey="translation.pleaseFillIn"/>
                                        </div>
                                        <div className="inputs">
                                            {errors.time}
                                            <TextField
                                                id="filled-name"
                                                label={t('translation.yourName')}
                                                name="name"
                                                value={values.name}
                                                onChange={e => setFieldValue('name', e.target.value)}
                                                margin="normal"
                                                variant="filled"
                                            />
                                            <OnSubmitValidationError error={errors.name}/>
                                            <TextField
                                                id="filled-name"
                                                name="companyName"
                                                value={values.companyName}
                                                onChange={e => setFieldValue('companyName', e.target.value)}
                                                label={t('translation.companyName')}
                                                margin="normal"
                                                variant="filled"
                                            />
                                            <OnSubmitValidationError error={errors.companyName}/>
                                            <TextField
                                                id="filled-name"
                                                name="email"
                                                htmlname="email"
                                                value={values.email}
                                                onChange={e => setFieldValue("email", e.target.value)}
                                                label={t('translation.email')}
                                                margin="normal"
                                                type="email"
                                                variant="filled"
                                                required
                                            />

                                            <Select
                                                value={values.companySize === '' ? t('translation.companySize') : values.companySize}
                                                name="companySize"
                                                htmlname="companySize"
                                                onChange={e => setFieldValue("companySize", e)}
                                                className="company-size-select"
                                            >
                                                <Option value="1-20">1-20 {t('translation.employees')}</Option>
                                                <Option value="21-100">21-100 {t('translation.employees')}</Option>
                                                <Option value="101-500">101-500 {t('translation.employees')}</Option>
                                                <Option value="501-1000">501-1000 {t('translation.employees')}</Option>
                                                <Option value="1000+">1000+ {t('translation.employees')}</Option>
                                            </Select>

                                            <OnSubmitValidationError error={errors.companySize}/>
                                            <Button className="submit-button" type="submit">
                                                {t('translation.submit')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                    <div className="team">
                        <img src={team} alt="team"/>
                    </div>
                </div>
                <SuccesMesage visible={this.state.modalVisible} closeModal={this.closeModal}
                              language={this.props.language}/>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(ContactForm);