import React from 'react';
import "./Alerts.scss";
import alertsPhone from "../../../assets/alerts-phone.svg";
import LazyLoad from "react-lazyload";
import alerts from "../../../assets/alerts.svg";
import {Trans, useTranslation} from 'react-i18next';

const Alerts = props => {
    const {t} = useTranslation();

    return (
        <div className="alerts">
            <div className="phone">
                <img src={alertsPhone} alt="alerts-phone"/>
            </div>
            <div className="alerts-body">
                <div className="alerts-texts">
                    <LazyLoad offset={-200}>
                        <div className="alerts-text-one slide-in-bottom">
                            <div className="alerts-text-one-header">
                                {t('translation.alerts')}
                            </div>
                            <div className="alerts-text-one-body">
                                <Trans i18nKey="translation.informMultipleReceivers"/>
                            </div>
                        </div>
                    </LazyLoad>
                    <LazyLoad offset={-200}>
                        <div className="alerts-text-two slide-in-bottom">
                            <Trans i18nKey="translation.allowCoordinators"/>
                        </div>
                    </LazyLoad>
                </div>
                <LazyLoad offset={-200}>
                    <div className="alerts-image slide-in-right">
                        <img src={alerts} alt="alerts"/>
                    </div>
                </LazyLoad>
            </div>
        </div>
    );
};

export default Alerts;