import React, {Component} from "react";
import "./Terms.scss";
import TermsHeader from "../TermsHeader/TermsHeader";
import Footer from "../OmegoPage/Footer/Footer";

class Terms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="terms">
                <TermsHeader/>

                <div className="terms-container">
                    <div className="content-list">
                        <h3 className="contents-text">CONTENTS</h3>
                        <p className="point-paragraph"><a href="#definition">§ 1. Definitions</a></p>
                        <p className="point-paragraph"><a href="#general-provisions">§ 2. General Provisions</a></p>
                        <p className="point-paragraph"><a href="#user-registration">§ 3. User Registration</a></p>
                        <p className="point-paragraph"><a href="#obligations-user">§ 4. Obligations of User</a></p>
                        <p className="point-paragraph"><a href="#obligations-rights">§ 5. Obligations and Rights of the
                            Provider</a></p>
                        <p className="point-paragraph"><a href="#termination-of-use">§ 6. Termination of Use</a></p>
                        <p className="point-paragraph"><a href="#personal-data-protection">§ 7. Personal Data
                            Protection</a></p>
                        <p className="point-paragraph"><a href="#final-provisions">§ 8. Final Provisions</a></p>
                    </div>

                    <div className="content">
                        <h1 className="main-title">Terms & Conditions</h1>
                        <div>
                            <p>These terms of service governing the provision of electronic services (hereinafter the
                                “Terms of Service”) specify:</p>
                            <div className="markers-list">
                                <ul>
                                    <li>the rules, scope, and the terms and conditions of use of the Omego Fleet
                                        application(hereinafter “OMEGO”) referred to as the “Application”, to be
                                        operated on
                                        mobile devices (with Android, IOS or Windows Phone system), as well as web
                                        browsers,
                                    </li>
                                    <li>personal data protection rules.</li>
                                </ul>
                            </div>

                            <p>
                                To use the OMEGO application you must read and accept the Terms of Service.
                                Services available via the application are provided by <strong>Delta Media AS, a
                                Norwegian
                                private
                                limited ability company with VAT nr. 916747330</strong>, hereinafter referred to as the
                                “Provider”
                            </p>
                        </div>

                        <div className="section" id="definition">
                            <h3 className="section-header">§ 1. Definitions</h3>

                            <ol className="ordered-list">
                                <li>OMEGO application - mobile application or web application (software) used to manage
                                    the User’s transport, which, by connecting to the internet, enables in particular
                                    the
                                    planning and control of drivers’ work time, route planning, tracking of the route
                                    map
                                    (operation view), and internal communication between employees,
                                </li>

                                <li>
                                    Terms of Service - this document specifying the terms and conditions of use of
                                    theApplication
                                </li>

                                <li>Provider - Delta Media AS, a Norwegian private limited liability company with VAT
                                    nr.916747330, administered by the laws of Norway,
                                </li>

                                <li>User – entrepreneur, who in order to be able to use the services provided by
                                    the Provider must register in the Application and accept the Terms of Service,
                                </li>

                                <li>Registration - activity performed by the User consisting in filling in of the
                                    registration form provided by the Provider on terms and conditions specified in the
                                    Terms of Service, necessary to obtain access to fully functional Application.
                                </li>

                                <li>Password - sequence of characters (digits, letters, special characters) specified
                                    by the User during the registration process, necessary to obtain access to the
                                    Account.
                                </li>

                                <li>Account - part of the Application created by the Provider after correct
                                    Registration, through which the User can perform specific actions
                                </li>

                                <li>Agreement - agreement concluded between the User and the Provider concerning the
                                    provision of electronic services in accordance with the terms and
                                    conditions specified herein
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="general-provisions">
                            <h3 className="section-header">§ 2. General Provisions</h3>

                            <ol className="ordered-list">
                                <li>The Provider is authorised to store and process the User's personal data, and is
                                    the owner of the manager of the application as part of which, in connection with
                                    the operation of the Application, he will obtain in particular (in addition to
                                    personal data) the following information: counter party details, technological,
                                    technical, organisational and other information of commercial value regardless of
                                    the
                                    content.
                                </li>

                                <li>In order to use the application it is necessary to connect a mobile device to
                                    the internet or a web browser compliant with the HTML5 and JavaScript standard,
                                    as well as to have an active GPS function.
                                </li>

                                <li>
                                    Prior to the conclusion of the agreement the Provider provides the Terms of
                                    Service to the Users via the Application in a manner that enables for the Terms of
                                    Service to be downloaded, saved, and printed out and accepted in the manner
                                    indicated therein.
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="user-registration">
                            <h3 className="section-header">§ 3. User Registration</h3>

                            <ol className="ordered-list">
                                <li>The User must be registered to obtain access to the Application.</li>

                                <li>The registration covers filling in the provided form with the required personal
                                    data, and
                                    accepting the Terms of Service.
                                </li>

                                <li>
                                    After registration the company administrator/system administrator creates
                                    account names
                                    and passwords for the users.
                                </li>

                                <li>
                                    Following successful registration it is assumed that the User and the Provider
                                    have concluded agreement for the provision of electronic services. The subject of
                                    such
                                    anagreement shall be: maintenance of the User Account, providing the User withaccess
                                    to
                                    the Account and full functionality of the Application.
                                </li>

                                <li>
                                    Following registration the User agrees for personal and address data to be stored
                                    inthe
                                    application, including data referred to in § 2(1).
                                </li>

                                <li>
                                    The User may access and amend the data referred to in § 3(3).
                                </li>

                                <li>
                                    The User is obligated to update the User’s data immediately after every change,
                                    not later
                                    than within 3 business days of such a change.
                                </li>

                                <li>Account of a specific User cannot be transferred to another User without
                                    the Provider’s
                                    consent expressed in writing, otherwise null and void.
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="obligations-user">
                            <h3 className="section-header">§ 4. Obligations of the User</h3>

                            <ol className="ordered-list">
                                <li> User shall use the Application in accordance with the applicable provisions oflaw,
                                    the Terms of Service, and the principles of community life, including the
                                    general rules of the internet and mobile applications.
                                </li>

                                <li>The User shall in particular:
                                    <ul>
                                        <li>use the Application in a manner not interfering with its operation,</li>

                                        <li>use the copyrighted material available in the Application (without obtaining
                                            the consent of the right holders) in a fair manner,
                                        </li>

                                        <li>use the Application without disturbing other Users or the Provider, and
                                            respect the personal rights of other Users (right to privacy) and other
                                            rights.
                                        </li>
                                    </ul>
                                </li>

                                <li>The User shall always notify the Provider of every infringement of his right
                                    in connection with the use of the Application.
                                </li>

                                <li>The User shall not change, by-pass, or break the Application’s security codes,
                                    either acting independently or with the participation of third parties, and shall
                                    not
                                    undertake actions that would cause the Application to be damages or overloaded.
                                </li>

                                <li>The User holds no rights to the content or materials made available by the
                                    Provider via the Application. The User has the right to use such content or
                                    materials
                                    only in accordance with the fair use doctrine. Copying, modification, dissemination,
                                    or reproduction of the provided content and materials, in part or in full, is
                                    prohibited.
                                </li>

                                <li>The User shall prevent access to the application by third parties. The User is
                                    solely responsible for the confidentiality and security of the User’s account, and
                                    shall immediately notify the Provider of every instance of unauthorised use of the
                                    User’sAccount in the Application by third parties.
                                </li>

                                <li>In the event of breach by the User of any obligation under the applicable
                                    provisions of law or the Terms of Service when using the Application, the Provider
                                    shall be released from liability towards third parties for such a breach. The User
                                    shall pay damages for the damage suffered by the Provider as a result of a breach of
                                    any of the rules governing the use of Application.
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="obligations-rights">
                            <h3 className="section-header">§ 5. Obligations and Rights of the Provider</h3>
                            <ol className="ordered-list">
                                <li>The Provider is responsible for the services provided though electronic
                                    means, governed by these Terms of Service.
                                </li>

                                <li>The Provider assures that the Application is publicly available, and any
                                    failures caused by improper operation of the Application shall be removed as quickly
                                    as possible. The User shall not however be entitled to claims related to
                                    ensuring constant and uninterrupted availability of the Application.
                                </li>

                                <li>The Application remains under the Provider’s constant technical supervision in
                                    order to ensure its correct operation. The Provider does not guarantee
                                    uninterrupted availability of all Application functionalities, or their flawless
                                    operation.
                                </li>

                                <li>The Provider shall not be liable for damage resulting from infringement of rights
                                    of third parties by the Users, or damage resulting from interrupted availability of
                                    all or some functions of the Application and services, applications, and websites
                                    provided by third parties.
                                </li>

                                <li>
                                    The Provider shall not be liable for technical problems or limitations of ICT
                                    systems installed on mobile devices / web browser which prevent the User from using
                                    the Application and the services offered, or which hinder such use.
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="termination-of-use">
                            <h3 className="section-header">§ 6. Termination of Use</h3>
                            <p>If it is found that the User acts in a manner contrary to law or the Terms of Service,
                                or breaches the principles of community life, or hurts the legitimate interest of the
                                Provider or third parties, the Provider may take any legal action, including restrict or
                                block access to the Application.</p>
                        </div>


                        <div className="section" id="personal-data-protection">
                            <h3 className="section-header">§ 7. Personal Data Protection</h3>
                            <ol className="ordered-list">
                                <li>
                                    All personal data provided by the User will be stored and processed in
                                    compliance with the provisions of law.
                                </li>

                                <li>
                                    All personal data and data described in § 2(1) collected by the Provider are
                                    stored and processed in a manner necessary for the proper operation of the
                                    Application.
                                </li>

                                <li>
                                    The User may grant consent for the use and storage by the Provider of personal
                                    data for the purpose of advertising, market research, or making commercial offers,
                                    as
                                    the need may be. The provision of consent for the Provider to process personal data
                                    for commercial purposes is voluntary and may be at any time stopped or revoked by
                                    the User via electronic means.
                                </li>

                                <li>
                                    The User grants consent for the User’s personal data to be made available
                                    for purposes connected with the compliance with the Application Terms of Service, or
                                    topursuit of related legal claims necessary to use the Application.
                                </li>

                                <li>
                                    The User’s declaration of consent for the storing and processing of personal data
                                    is voluntary and the User has the right to access such data and demand that they
                                    be rectified, amended, erased, or completed. The request to have data erased
                                    shall mean automatic termination of the right to use the Application and the
                                    termination of the agreement for the provisions of service covered by these Terms of
                                    Service, subject to the contractual provisions related to its term.
                                </li>
                            </ol>
                        </div>

                        <div className="section" id="final-provisions">
                            <h3 className="section-header">§ 8. Final Provisions</h3>
                            <ol className="ordered-list">
                                <li>
                                    Should any clause or provisions of these Terms of Service be deemed by a court oflaw
                                    or other authority to be invalid, unenforceable, or unlawful in any respect,
                                    other provisions shall remain in full force and effect and shall remain valid and
                                    binding. Any provisions deemed invalid, unenforceable, or unlawful shall be replaced
                                    by other provisions that best reflect the economic and commercial purpose of the
                                    Application.
                                </li>

                                <li>
                                    The provider reserves the right to amend the Terms of Service. Amended Terms
                                    of Service shall enter into force upon the release of a new version. The User may
                                    object to the amendments to the Terms of Service in writing within 7 days of their
                                    release.User objection shall result in automatic termination of the right to use the
                                    Application.
                                </li>

                                <li>
                                    In matters not regulated herein the provision of Norwegian law shall apply.
                                </li>
                            </ol>
                        </div>


                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Terms;
