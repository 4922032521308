import React, {Component} from 'react';
import MobileTerms from "../MobileView/MobileTerms/MobileTerms";
import Terms from "../Terms/Terms";

class ViewTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };

        window.scrollTo(0, 0);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidMount() {

    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    render() {
        const {width} = this.state;
        const isMobile = width <= 500;

        // return (
        //     <OmegoPage currentScreenWidth={width}/>
        // )

        if (isMobile) {
            return (
                <MobileTerms currentScreenWidth={width}/>
            )
        } else {
            return (
                <Terms currentScreenWidth={width}/>
            )
        }


    }
}

export default ViewTerms;