import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';

export default function Slide({
                                  firstImage,
                                  secondImage,
                                  currentScreenWidth,
                                  sliderImage,
                                  language
                              }) {
    const [boxesWidth, setBoxesWith] = useState(50);
    const [imgToShow, setImgToShow] = useState(null);
    const [windowWidth, setWindowWidth] = useState(currentScreenWidth);
    const testRef = useRef(null);

    const {t} = useTranslation();

    const handleMouseMove = ({clientX}) => {
        let correctWidth;

        let test = window.innerWidth;

        correctWidth = clientX / (test / 105);

        if (correctWidth > 1 && correctWidth < 99) {
            const dragImg = new Image(0, 0);
            dragImg.src =
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

            setImgToShow(dragImg);
            setBoxesWith(correctWidth);
        }
    };

    const dontDisplay = e => {
        e.dataTransfer.setDragImage(imgToShow, 0, 0);
    };

    const handleMouseDown = ({clientX, clientY}) => {
    };

    useEffect(() => {
        setWindowWidth(currentScreenWidth);
    }, [windowWidth, currentScreenWidth]);

    useEffect(() => {
        const dragImg = new Image(0, 0);
        dragImg.src =
            "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

        setImgToShow(dragImg);

        window.addEventListener("drag", handleMouseMove);
        return () => {
            window.removeEventListener("drag", handleMouseMove);
        };
    }, []);

    return (
        <div ref={testRef} style={{height: "100%", position: "relative"}}>
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    width: `${boxesWidth}%`,
                    background: `url('${firstImage}') 0% 0% / cover no-repeat`,
                    zIndex: 3
                }}
            >
                <div
                    draggable
                    style={{
                        width: 50,
                        cursor: "none",
                        height: 50,
                        background: `url('${sliderImage}') 0% 0% / cover no-repeat`,
                        backgroundColor: "white",
                        zIndex: 2,
                        top: "50%",
                        borderRadius: "50%",
                        position: "absolute",
                        right: -25
                    }}
                    onDrag={e => handleMouseDown(e)}
                    onDragStart={e => dontDisplay(e)}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",

                    background: `url('${secondImage}') 0% 0% / cover no-repeat`,
                    zIndex: 2
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        marginRight: "2%",
                        marginBottom: "2%",
                        fontWeight: "bold",
                        fontSize: "1.3vw",
                        color: "#1B51DC"
                    }}
                >
                    {t('translation.withOmego')}
                </div>
            </div>
        </div>
    );
}