import React from 'react';
import "./MobileFooter.scss";
import {NavLink} from "react-router-dom";

function MobileFooter(props) {

    const openLinked = () => {
        window.open('https://www.linkedin.com/company/omegofleet');
    };

    return (
        <div className="footer">
            <div className="terms-footer">
                <NavLink to="/">2019 © Omego Fleet</NavLink> • <NavLink to="/site/terms">Terms &
                Conditions</NavLink> • <NavLink to="/site/privacy-policy">Privacy Policy</NavLink>
            </div>
        </div>
    );
}

export default MobileFooter;