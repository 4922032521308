import React from 'react';
import LazyLoad from "react-lazyload";
import roadTrip from "../../../assets/road-trip.svg";
import {Tooltip} from "antd";
import "./StartRoad.scss";
import {Trans, useTranslation} from 'react-i18next';

const StartRoad = props => {

    const {t} = useTranslation();

    return (
        <div className="start-road">
            <LazyLoad offset={-200} placeholder={<div>
                <div className="road-trip scale-in-center" style={{color: "transparent"}}>
                    <Trans i18nKey="translation.startRoad"/>
                </div>
            </div>}>

                <div className="road-trip scale-in-center">
                    <Trans i18nKey="translation.startRoad"/>
                </div>

            </LazyLoad>
            <div className="road-trip-desc">
                <LazyLoad offset={-200} placeholder={<div style={{width: "50%"}}></div>}>
                    <div className="desc slide-in-elliptic-left-fwd">
                        <div className="header">
                            <Trans i18nKey="translation.interactiveShiftplan"/>
                        </div>
                        <div className="body">
                            <Trans i18nKey="translation.createShiftAccording"/>
                        </div>
                    </div>
                </LazyLoad>
                <div className="image">
                    <img src={roadTrip} alt="road-trip"/>
                    <Tooltip placement="topLeft"
                             title={t('translation.redline')}>
                        <div className="first-dot pulsate-fwd"/>
                    </Tooltip>
                    <Tooltip placement="topRight"
                             title={t('translation.informationAboutEmployers')}>
                        <div className="second-dot pulsate-fwd"/>
                    </Tooltip>
                </div>
            </div>
            <div className="aware">
                <LazyLoad offset={-200} placeholder={<div style={{height: '110px'}}/>}>
                    <div className="aware-text slide-in-bottom">
                        <Trans i18nKey="translation.beAware"/>
                    </div>
                </LazyLoad>
            </div>
        </div>
    );
};

export default StartRoad;