import React, {Component} from 'react';
import omegoHeader from "../../../assets/omego-fleet.svg";
import "./MobileTermsHeader.scss";
import {NavLink} from "react-router-dom";


class MobileTermsHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'English',
        }
    }

    render() {
        return (
            <div className="mobile-terms-header">
                <div>
                    <NavLink to="/">
                        <img src={omegoHeader} alt="omego-fleet" className="mobile-omego-logo"/>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default MobileTermsHeader;