import React, {Component} from 'react';
import cloud from "../../../assets/cloud.svg";
import phoneDay from "../../../assets/phone-day.svg";
import phoneNight from "../../../assets/phone-night.svg";
import moon from "../../../assets/moon.svg";
import LazyLoad from "react-lazyload";
import "./Phone.scss";
import {Trans} from 'react-i18next';

class Phone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nightMode: false,
        }
    }

    changePhoneMode = () => {
        this.setState({nightMode: !this.state.nightMode});
    };

    render() {
        return (
            <div className="phone-container">
                <img src={cloud} className="cloud" alt="cloud"/>
                <div className="phone">
                    {this.state.nightMode ? <img src={phoneDay} alt="phone"/> :
                        <img src={phoneNight} alt="phone"/>}
                </div>
                <div className="body">
                    <div className="header">
                        <div className="moon">
                            <img src={moon} onClick={this.changePhoneMode}
                                 className={!this.state.nightMode ? 'heartbeat' : null}
                                 alt="moon"/>
                            {this.state.nightMode ? <div
                                    style={{textAlign: "center", fontWeight: 'bold'}}>ON</div> :
                                <div
                                    style={{textAlign: "center", fontWeight: 'bold'}}>OFF</div>}
                        </div>
                        <div className="header-text">
                            <Trans i18nKey="translation.makeDriversLife"/>
                        </div>
                    </div>
                    <LazyLoad offset={-200}>
                        <div className="desc centered-text slide-in-right">
                            <div className="desc-text" style={{display: 'none'}}>
                                <p>Our app</p>
                            </div>
                            <div className="desc-text">
                                <Trans i18nKey="translation.ourAppGuidesDrivers"/>
                            </div>
                        </div>
                    </LazyLoad>
                </div>
            </div>
        );
    }
}

export default Phone;