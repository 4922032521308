import React from 'react';
import "./ImageSlider.scss";
import cardRouteBlue from "../../../assets/cards-route-blue.svg";
import cardRoute from "../../../assets/cards-route.svg";
import cardCalendarBlue from "../../../assets/cards-calendar-blue.svg";
import cardCalendar from "../../../assets/cards-calendar.svg";
import cardCommBlue from "../../../assets/cards-comm-blue.svg";
import cardComm from "../../../assets/cards-comm.svg";
import Slide from "../../Slide";
import blueArrowSlide from "../../../assets/blue-arrow-slide.svg";
import slideRouteOne from "../../../assets/slide-route-one.png";
import slideShiftOne from "../../../assets/slide-shift-one.png";
import slideCommOne from "../../../assets/slide-comm-one.png";
import slideRouteTwo from "../../../assets/slide-route-two.png";
import slideShiftTwo from "../../../assets/slide-shift-two.png";
import slideCommTwo from "../../../assets/slide-comm-two.png";
import {Trans, useTranslation} from 'react-i18next';


const ImageSlider = props => {

    const {t} = useTranslation();

    const getLeftImage = () => {
        switch (props.taskSelected) {
            case 1:
                return slideRouteOne;
            case 2:
                return slideShiftOne;
            case 3:
                return slideCommOne;
            default:
                return;
        }
    };

    const getRightImage = () => {
        switch (props.taskSelected) {
            case 1:
                return slideRouteTwo;
            case 2:
                return slideShiftTwo;
            case 3:
                return slideCommTwo;
            default:
                return;
        }
    };

    return (
        <div className="image-slider">
            <div className="cards" id="goto">
                <div className={props.taskSelected === 1 ? 'card-selected' : 'card'}
                     onClick={() => props.changeTaskSelected(1)}>
                    <div className="card-content">
                        {props.taskSelected === 1 ?
                            <img src={cardRouteBlue} alt="route-planning"/> :
                            <img src={cardRoute} alt="route-planning"/>}
                        <div className="content-title"><h2
                            className="content-title">{t('translation.routePlanning')}</h2></div>
                        <div className="content-desc">
                            <Trans i18nKey="translation.letOurRecord"/>
                        </div>
                    </div>
                </div>
                <div className={props.taskSelected === 2 ? 'card-selected' : 'card'}
                     onClick={() => props.changeTaskSelected(2)}>
                    <div className="card-content">
                        {props.taskSelected === 2 ?
                            <img src={cardCalendarBlue} alt="shift-planning"/> :
                            <img src={cardCalendar} alt="shift-planning"/>}
                        <div className="content-title"><h2
                            className="content-title">{t('translation.shiftPlanning')}</h2></div>
                        <div className="content-desc">
                            <Trans i18nKey="translation.createAndManage"/>
                        </div>
                    </div>
                </div>
                <div className={props.taskSelected === 3 ? 'card-selected' : 'card'}
                     onClick={() => props.changeTaskSelected(3)}>
                    <div className="card-content">
                        {props.taskSelected === 3 ?
                            <img src={cardCommBlue} alt="Communicator"/> :
                            <img src={cardComm} alt="Communicator"/>}
                        <div className="content-title">{t('translation.communicator')}</div>
                        <div className="content-desc">
                            <Trans i18nKey="translation.increaseComfort"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-images">
                <Slide language={props.language} sliderImage={blueArrowSlide}
                       currentScreenWidth={props.currentScreenWidth}
                       firstImage={getLeftImage()} secondImage={getRightImage()}/>
            </div>
        </div>
    );
};

export default ImageSlider;