import React, {Component} from 'react';
import "./ContactUs.scss";
import {withTranslation} from 'react-i18next';

class ContactUs extends Component {
    constructor(props) {
        super(props);
    }

    viewElementContact = () => {
        const element = document.getElementById("form");
        element.scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="contact-us" onClick={this.viewElementContact}>
                    <div>{t('translation.contactUsBar')}</div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ContactUs);