import React, {useState} from 'react';
import "./MobileShiftPlan.scss";
import miniCalendar from "../../../assets/mobile/mini-calendar.svg";
import {Trans, useTranslation} from "react-i18next";
import roadTrip from "../../../assets/road-trip.svg";
import road from "../../../assets/mobile/costam.svg";

const MobileShiftPlan = () => {

    const {t} = useTranslation();

    const [firstCloud, setFirstCloud] = useState(true);
    const [secondCloud, setSecondCloud] = useState(true);
    const language = localStorage.getItem('language');

    return (
        <div className="mobile-shiftplan">
            <div style={{display: 'flex', flexDirection: 'row'}} className="sp">
                <img src={miniCalendar}/>
                <div>{t('translation.shiftPlanning')}</div>
            </div>
            <div className="cm" style={{padding: '10px'}}>
                <Trans i18nKey="translation.createAndManage"/>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10%'}}>
                <div className="image">
                    <img src={roadTrip} alt="road-trip" className="ab"/>
                    <div className="first-dot pulsate-fwd">
                    </div>
                    {firstCloud ? <div className="first-cloud">
                        {t('translation.redline')}
                    </div> : null}
                    {firstCloud ? <div className="first-cloud">
                        {t('translation.redline')}
                    </div> : null}
                    <div className="second-dot pulsate-fwd">
                    </div>
                    {secondCloud && language === 'en' ?
                        <div className="second-cloud">{t('translation.informationAboutEmployers')}</div> : null}
                    {secondCloud && language !== 'en' ?
                        <div className="second-cloud">{t('translation.informationAboutEmployers')}</div> : null}
                </div>
            </div>
            <div style={{textAlign: 'center', marginTop: '10%', color: '#182E59'}}>
                <div className="header">
                    <Trans i18nKey="translation.mobile.createShiftAccording"/>
                </div>
            </div>
            <div className="road">
                <img src={road}/>
            </div>
            <hr/>
            <div className="aware-text slide-in-bottom">
                <Trans i18nKey="translation.mobile.beAware"/>
            </div>
        </div>
    );
};

export default MobileShiftPlan;