import React, {useState} from 'react';
import "./MobileOmegoApp.scss";
import mobileIcon from "../../../assets/mobile/mobile-icon.svg";
import {Trans, useTranslation} from "react-i18next";
import phoneDay from "../../../assets/phone-day.svg";
import phoneNight from "../../../assets/phone-night.svg";
import moon from "../../../assets/moon.svg";
import alertsIcon from "../../../assets/mobile/alerts-icon.svg";
import alertsMobile from "../../../assets/mobile/alerts-mobile.svg";
import Siriwave from "react-siriwave";

const MobileOmegoApp = () => {

    const {t} = useTranslation();
    const [nightMode, setNightMode] = useState(false);

    const changePhoneMode = () => {
        setNightMode(!nightMode);
    };

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row'}} className="sp">
                <img src={mobileIcon}/>
                <div>Omego App</div>
            </div>
            <div className="header-text" style={{padding: '10px'}}>
                <Trans i18nKey="translation.makeDriversLife"/>
            </div>
            <div className="phone-container ">
                <div className="phone">
                    {nightMode ? <img src={phoneDay}/> :
                        <img src={phoneNight}/>}
                </div>
                <div className="body">
                    <div className="header">
                        <div className="moon">
                            <img src={moon} onClick={changePhoneMode}
                                 className={!nightMode ? 'heartbeat' : null}
                                 alt="moon"/>
                            {nightMode ? <div
                                    style={{textAlign: "center", fontWeight: 'bold'}}>ON</div> :
                                <div
                                    style={{textAlign: "center", fontWeight: 'bold'}}>OFF</div>}
                        </div>
                    </div>
                    <div className="desci slide-in-right">
                        <div className="line"></div>
                        <div className="desc-text">
                            <Trans i18nKey="translation.mobile.ourAppGuidesDrivers"/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}} className="sp">
                <img src={alertsIcon}/>
                <div>{t('translation.alerts')}</div>
            </div>
            <div className="header-text" style={{padding: '20px'}}>
                <Trans i18nKey="translation.informMultipleReceivers"/>
            </div>
            <div style={{position: 'relative', marginTop: '10%'}}>
                <img src={alertsMobile} style={{width: '100%'}}/>
                <div style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: '50%',
                    paddingRight: 20,
                    fontSize: '3.2vw',
                    color: '#1F293B',
                    paddingTop: 10,
                    lineHeight: 2
                }}>
                    <Trans i18nKey="translation.mobile.allowCoordinators"/>
                </div>

            </div>
            <div className="siri">
                <Siriwave style={"ios9"} amplitude={3}/>
            </div>
            <div className="sp" style={{padding: '20px'}}>
                {t('translation.voice')}
            </div>
            <div className="header-text">
                <Trans i18nKey="translation.allowsDriversToConcentrate"/>
            </div>
            <div className="desci slide-in-bottom">
                <Trans i18nKey="translation.mobile.allowCoordinators"/>
            </div>
        </>
    );
};

export default MobileOmegoApp;