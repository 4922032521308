import React, {useEffect} from 'react';
import './App.css';
import View from "./components/View/View";
import {BrowserRouter, Route} from "react-router-dom";
import ViewTerms from "./components/ViewTerms/ViewTerms";
import ReactGA from 'react-ga';
import ViewPrivacyPolicy from "./components/ViewPrivacyPolicy/ViewPrivacyPolicy";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './translations/en';
import no from './translations/no';
import pl from "./translations/pl";

i18n.use(initReactI18next).init({
    resources: {en: {translation: en}, no: {translation: no}, pl: {translation: pl}},
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

const App = props => {
    useEffect(() => {
        if (localStorage.getItem('language') != null) {
            i18n.changeLanguage(localStorage.getItem('language'));
        }
    }, []);


    function initializeReactGA() {
        ReactGA.initialize('UA-149597148-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    initializeReactGA();
    return (
        <div>
            <BrowserRouter>
                <Route exact path="/" component={View}/>
                <Route path="/site/terms" component={ViewTerms}/>
                <Route path="/site/privacy-policy" component={ViewPrivacyPolicy}/>
            </BrowserRouter>
        </div>
    );
}

export default App;
