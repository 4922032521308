import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import trustedBy from "../../../assets/mobile/partnersMobile.svg";

const MobileTrustedBy = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="road-trip">
                <Trans i18nKey="translation.mobile.trustedBy"/>
            </div>
            <hr/>
            <div style={{marginTop: '15%'}}>
                <img src={trustedBy} style={{display: 'block', margin: '0 auto'}} alt="trusted by"/>
            </div>
            <div className="desci-italic" style={{margin: '20px'}}>
                <Trans i18nKey="translation.mobile.weAreImpressed"/>
            </div>
            <div className="sign slide-in-left">
                Jon Kåre Stene
            </div>
            <div className="sign-desc slide-in-left">
                <Trans i18nKey="translation.partnerChef"/>
            </div>
        </>
    );
};

export default MobileTrustedBy;