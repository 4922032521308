import React from 'react';
import "./Footer.scss";
import omegoLogo from "../../../assets/Footer/omego-fleet-logo.svg";
import norwayFlag from "../../../assets/Footer/norway-flag.svg";
import polishFlag from "../../../assets/Footer/polish-flag.svg";
import usFlag from "../../../assets/Footer/us-flag.svg";
import {NavLink} from "react-router-dom";
import mediumIcon from "../../../assets/medium-icon.svg";
import twitterIcon from "../../../assets/twitterr-icon.svg";
import inIcon from "../../../assets/in-icon.svg";
import {useTranslation} from 'react-i18next';

const Footer = props => {

    const openLinked = () => {
        window.open('https://www.linkedin.com/company/omegofleet');
    };

    const {t} = useTranslation();

    return (
        <div className="footer">
            <div className="main-header">
                {t('translation.officeLocations')}
            </div>
            <div className="first-row">
                <div className="row-element">
                    <div className="flag">
                        <img src={norwayFlag} alt="norway flag"/>
                        <div className="header">
                            Norway
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div> Brysnveien 3,</div>
                            <div>0667 Oslo</div>
                        </div>
                    </div>
                </div>
                <div className="row-element">
                    <div className="flag">
                        <img src={polishFlag} alt="polish flag"/>
                        <div className="header">
                            Poland
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div>ul. Grabiszyńska 214/7,</div>
                            <div>53-235 Wrocław</div>
                        </div>
                    </div>
                </div>
                <div className="row-element">
                    <div className="flag">
                        <img src={usFlag} alt="usa flag"/>
                        <div className="header">
                            United States
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div>470 Ramona St. Palo Alto,</div>
                            <div>94301 Palo Alto CA</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second-row">
                <div className="row-element">
                    <div className="flag">
                        <div className="header">
                            {t('translation.information')}
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot">
                                    </div>
                                </div>
                                <NavLink to="/site/terms">
                                    <div className="desc-text"
                                         className="dark-ahref">{t('translation.termsAndConditions')}</div>
                                </NavLink>
                            </div>
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot">
                                    </div>
                                </div>
                                <NavLink to="/site/privacy-policy">
                                    <div className="desc-text"
                                         className="dark-ahref">{t('translation.privacyPolicy')}</div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-element">
                    <div className="flag">
                        <div className="header">
                            {t('translation.contactUs')}
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot">
                                    </div>
                                </div>
                                <div className="desc-text"><a href="mailto:contact@omegofleet.com"
                                                              className="dark-ahref">contact@omegofleet.com</a>
                                </div>
                            </div>
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot">
                                    </div>
                                </div>
                                <div className="desc-text"><a href="tel:+4794478464" className="dark-ahref">+47 944 784
                                    64</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-element">
                    <div className="flag">
                        <div className="header">
                            {t('translation.findUs')}
                        </div>
                    </div>
                    <div className="text">
                        <div className="desc">
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot" style={{backgroundColor: 'transparent'}}>
                                    </div>
                                </div>
                                <div className="desc-text-icons">
                                    <img src={twitterIcon} className="pointer" alt="twitter icon"/>

                                    <a href="https://www.linkedin.com/company/omegofleet" rel="nofollow">
                                        <img src={inIcon} className="pointer" alt="linkedin icon"/>
                                    </a>
                                    <img src={mediumIcon} className="pointer" alt="medium icon"/>
                                </div>
                            </div>
                            <div className="desc-rec">
                                <div className="dot-box">
                                    <div className="dot" style={{backgroundColor: 'transparent'}}>
                                    </div>
                                </div>
                                <div className="desc-text"><img src={omegoLogo} className="omego-fleet-logo-footer"
                                                                alt="omego fleet icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="terms-footer">
                <NavLink to="/">2019 © Omego Fleet</NavLink> • <NavLink
                to="/site/terms">{t('translation.termsAndConditions')}</NavLink> • <NavLink
                to="/site/privacy-policy">{t('translation.privacyPolicy')}</NavLink>
            </div>
        </div>
    );
};

export default Footer;