import React, {Component} from 'react';
import "./Calculator.scss";
import calcPeople from "../../../assets/calc-people.svg";
import calcMoney from "../../../assets/calc-money.svg";
import calcClockRight from "../../../assets/calc-clock-right.svg";
import {Slider} from "antd";
import calcFuel from "../../../assets/calc-fuel.svg";
import calcCo from "../../../assets/calc-co.svg";
import calcClockLeft from "../../../assets/calc-clock-left.svg";
import {Trans, withTranslation} from 'react-i18next';

class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leftValue: 5,
            hr: 0,
            driver: 0,
            driversNumber: 0,
            time: 0,
            rightValue: 10,
        }
    }

    componentDidMount() {
        this.calculateLeft();
        this.calculateRight();
    }

    calculateRight = (value) => {
        if (value !== undefined) {
            this.setState({rightValue: value});
            switch (value.toString()) {
                case '15':
                    this.setState({
                        fuel: 75000 * 0.15 * 1.4,
                        co: 2640 * (75000 * 0.15) * 0.001,
                        kilometers: 75000,
                        clock: 5250 * 2
                    });
                    return;
                case '14':
                    this.setState({
                        fuel: 70000 * 0.15 * 1.4,
                        co: 2640 * (70000 * 0.15) * 0.001,
                        kilometers: 70000,
                        clock: 4375 * 2
                    });
                    return;
                case '13':
                    this.setState({
                        fuel: 65000 * 0.15 * 1.4,
                        co: 2640 * (65000 * 0.15) * 0.001,
                        kilometers: 65000,
                        clock: 3500 * 2
                    });
                    return;
                case '12':
                    this.setState({
                        fuel: 60000 * 0.15 * 1.4,
                        co: 2640 * (60000 * 0.15) * 0.001,
                        kilometers: 60000,
                        clock: 2625 * 2
                    });
                    return;
                case '11':
                    this.setState({
                        fuel: 55000 * 0.15 * 1.4,
                        co: 2640 * (55000 * 0.15) * 0.001,
                        kilometers: 55000,
                        clock: 1750 * 2
                    });
                    return;
                case '10':
                    this.setState({
                        fuel: 50000 * 0.15 * 1.4,
                        co: 2640 * (50000 * 0.15) * 0.001,
                        kilometers: 50000,
                        clock: 1050 * 2
                    });
                    return;
                case '9':
                    this.setState({
                        fuel: 45000 * 0.15 * 1.4,
                        co: 2640 * (45000 * 0.15) * 0.001,
                        kilometers: 45000,
                        clock: 875 * 2
                    });
                    return;
                case '8':
                    this.setState({
                        fuel: 40000 * 0.15 * 1.4,
                        co: 2640 * (40000 * 0.15) * 0.001,
                        kilometers: 40000,
                        clock: 700 * 2
                    });
                    return;
                case '7':
                    this.setState({
                        fuel: 35000 * 0.15 * 1.4,
                        co: 2640 * (35000 * 0.15) * 0.001,
                        kilometers: 35000,
                        clock: 525 * 2
                    });
                    return;
                case '6':
                    this.setState({
                        fuel: 30000 * 0.15 * 1.4,
                        co: 2640 * (30000 * 0.15) * 0.001,
                        kilometers: 30000,
                        clock: 350 * 2
                    });
                    return;
                case '5':
                    this.setState({
                        fuel: 25000 * 0.15 * 1.4,
                        co: 2640 * (25000 * 0.15) * 0.001,
                        kilometers: 25000,
                        clock: 175 * 2
                    });
                    return;
                case '4':
                    this.setState({
                        fuel: 20000 * 0.15 * 1.4,
                        co: 2640 * (20000 * 0.15) * 0.001,
                        kilometers: 20000,
                        clock: 140 * 2
                    });
                    return;
                case '3':
                    this.setState({
                        fuel: 15000 * 0.15 * 1.4,
                        co: 2640 * (15000 * 0.15) * 0.001,
                        kilometers: 15000,
                        clock: 105 * 2
                    });
                    return;
                case '2':
                    this.setState({
                        fuel: 10000 * 0.15 * 1.4,
                        co: 2640 * (10000 * 0.15) * 0.001,
                        kilometers: 10000,
                        clock: 70 * 2
                    });
                    return;
                case '1':
                    this.setState({
                        fuel: 5000 * 0.15 * 1.4,
                        co: 2640 * (5000 * 0.15) * 0.001,
                        kilometers: 5000,
                        clock: 35 * 2
                    });
                    return;
            }

            return;
        }

        switch (this.state.rightValue.toString()) {
            case '15':
                this.setState({
                    fuel: 75000 * 0.15 * 1.4,
                    co: 2640 * (75000 * 0.15) * 0.001,
                    kilometers: 75000,
                    clock: 5250 * 2
                });
                return;
            case '14':
                this.setState({
                    fuel: 70000 * 0.15 * 1.4,
                    co: 2640 * (70000 * 0.15) * 0.001,
                    kilometers: 70000,
                    clock: 4375 * 2
                });
                return;
            case '13':
                this.setState({
                    fuel: 65000 * 0.15 * 1.4,
                    co: 2640 * (65000 * 0.15) * 0.001,
                    kilometers: 65000,
                    clock: 3500 * 2
                });
                return;
            case '12':
                this.setState({
                    fuel: 60000 * 0.15 * 1.4,
                    co: 2640 * (60000 * 0.15) * 0.001,
                    kilometers: 60000,
                    clock: 2625 * 2
                });
                return;
            case '11':
                this.setState({
                    fuel: 55000 * 0.15 * 1.4,
                    co: 2640 * (55000 * 0.15) * 0.001,
                    kilometers: 55000,
                    clock: 1750 * 2
                });
                return;
            case '10':
                this.setState({
                    fuel: 50000 * 0.15 * 1.4,
                    co: 2640 * (50000 * 0.15) * 0.001,
                    kilometers: 50000,
                    clock: 1050 * 2
                });
                return;
            case '9':
                this.setState({
                    fuel: 45000 * 0.15 * 1.4,
                    co: 2640 * (45000 * 0.15) * 0.001,
                    kilometers: 45000,
                    clock: 875 * 2
                });
                return;
            case '8':
                this.setState({
                    fuel: 40000 * 0.15 * 1.4,
                    co: 2640 * (40000 * 0.15) * 0.001,
                    kilometers: 40000,
                    clock: 700 * 2
                });
                return;
            case '7':
                this.setState({
                    fuel: 35000 * 0.15 * 1.4,
                    co: 2640 * (35000 * 0.15) * 0.001,
                    kilometers: 35000,
                    clock: 525 * 2
                });
                return;
            case '6':
                this.setState({
                    fuel: 30000 * 0.15 * 1.4,
                    co: 2640 * (30000 * 0.15) * 0.001,
                    kilometers: 30000,
                    clock: 350 * 2
                });
                return;
            case '5':
                this.setState({
                    fuel: 25000 * 0.15 * 1.4,
                    co: 2640 * (25000 * 0.15) * 0.001,
                    kilometers: 25000,
                    clock: 175 * 2
                });
                return;
            case '4':
                this.setState({
                    fuel: 20000 * 0.15 * 1.4,
                    co: 2640 * (20000 * 0.15) * 0.001,
                    kilometers: 20000,
                    clock: 140 * 2
                });
                return;
            case '3':
                this.setState({
                    fuel: 15000 * 0.15 * 1.4,
                    co: 2640 * (15000 * 0.15) * 0.001,
                    kilometers: 15000,
                    clock: 105 * 2
                });
                return;
            case '2':
                this.setState({
                    fuel: 10000 * 0.15 * 1.4,
                    co: 2640 * (10000 * 0.15) * 0.001,
                    kilometers: 10000,
                    clock: 75 * 2
                });
                return;
            case '1':
                this.setState({
                    fuel: 5000 * 0.15 * 1.4,
                    co: 2640 * (5000 * 0.15) * 0.001,
                    kilometers: 5000,
                    clock: 35 * 2
                });
                return;
        }


    };

    calculateLeft = (value) => {
        if (value !== undefined) {
            this.setState({leftValue: value});
            switch (value.toString()) {
                case '1':
                    this.setState({hr: 1 * 5600, driver: 1 * 9000, driversNumber: 1, time: 1 * 110});
                    return;
                case '2':
                    this.setState({hr: 1 * 5600, driver: 2 * 9000, driversNumber: 2, time: 2 * 110});
                    return;
                case '3':
                    this.setState({hr: 1 * 5600, driver: 3 * 9000, driversNumber: 3, time: 3 * 110});
                    return;
                case '4':
                    this.setState({hr: 1 * 5600, driver: 4 * 9000, driversNumber: 4, time: 4 * 110});
                    return;
                case '5':
                    this.setState({hr: 1 * 5600, driver: 5 * 9000, driversNumber: 5, time: 5 * 110});
                    return;
                case '6':
                    this.setState({hr: 1 * 5600, driver: 10 * 9000, driversNumber: 10, time: 10 * 110});
                    return;
                case '7':
                    this.setState({hr: 1 * 5600, driver: 15 * 9000, driversNumber: 15, time: 15 * 110});
                    return;
                case '8':
                    this.setState({hr: 1 * 5600, driver: 20 * 9000, driversNumber: 20, time: 20 * 110});
                    return;
                case '9':
                    this.setState({hr: 1 * 5600, driver: 25 * 9000, driversNumber: 25, time: 25 * 110});
                    return;
                case '10':
                    this.setState({hr: 2 * 5600, driver: 30 * 9000, driversNumber: 30, time: 30 * 110});
                    return;
                case '11':
                    this.setState({hr: 2 * 5600, driver: 50 * 9000, driversNumber: 50, time: 50 * 110});
                    return;
                case '12':
                    this.setState({hr: (3 * 5600), driver: 75 * 9000, driversNumber: 75, time: 75 * 110});
                    return;
                case '13':
                    this.setState({hr: 4 * 5600, driver: 100 * 9000, driversNumber: 100, time: 100 * 110});
                    return;
                case '14':
                    this.setState({hr: 4 * 5600, driver: 125 * 9000, driversNumber: 125, time: 125 * 110});
                    return;
                case '15':
                    this.setState({hr: 5 * 5600, driver: 150 * 9000, driversNumber: 150, time: 150 * 110});
                    return;
            }

            return;
        }

        switch (this.state.leftValue.toString()) {
            case '1':
                this.setState({hr: 1 * 5600, driver: 1 * 9000, driversNumber: 1, time: 1 * 110});
                return;
            case '2':
                this.setState({hr: 1 * 5600, driver: 2 * 9000, driversNumber: 2, time: 2 * 110});
                return;
            case '3':
                this.setState({hr: 1 * 5600, driver: 3 * 9000, driversNumber: 3, time: 3 * 110});
                return;
            case '4':
                this.setState({hr: 1 * 5600, driver: 4 * 9000, driversNumber: 4, time: 4 * 110});
                return;
            case '5':
                this.setState({hr: 1 * 5600, driver: 5 * 9000, driversNumber: 5, time: 5 * 110});
                return;
            case '6':
                this.setState({hr: 1 * 5600, driver: 10 * 9000, driversNumber: 10, time: 10 * 110});
                return;
            case '7':
                this.setState({hr: 1 * 5600, driver: 15 * 9000, driversNumber: 15, time: 15 * 110});
                return;
            case '8':
                this.setState({hr: 1 * 5600, driver: 20 * 9000, driversNumber: 20, time: 20 * 110});
                return;
            case '9':
                this.setState({hr: 1 * 5600, driver: 25 * 9000, driversNumber: 25, time: 25 * 110});
                return;
            case '10':
                this.setState({hr: 2 * 5600, driver: 30 * 9000, driversNumber: 30, time: 30 * 110});
                return;
            case '11':
                this.setState({hr: 2 * 5600, driver: 50 * 9000, driversNumber: 50, time: 50 * 110});
                return;
            case '12':
                this.setState({hr: (3 * 5600), driver: 75 * 9000, driversNumber: 75, time: 75 * 110});
                return;
            case '13':
                this.setState({hr: 4 * 5600, driver: 100 * 9000, driversNumber: 100, time: 100 * 110});
                return;
            case '14':
                this.setState({hr: 4 * 5600, driver: 125 * 9000, driversNumber: 125, time: 125 * 110});
                return;
            case '15':
                this.setState({hr: 5 * 5600, driver: 150 * 9000, driversNumber: 150, time: 150 * 110});
                return;
        }
    };

    formatTooltip = (value) => {
        switch (value) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
                return 4;
            case 5:
                return 5;
            case 6:
                return 10;
            case 7:
                return 15;
            case 8:
                return 20;
            case 9:
                return 25;
            case 10:
                return 30;
            case 11:
                return 50;
            case 12:
                return 75;
            case 13:
                return 100;
            case 14:
                return 125;
            case 15:
                return 150;
        }
    };

    formatTooltipRight = (value) => {
        switch (value) {
            case 1:
                return 5000;
            case 2:
                return 10000;
            case 3:
                return 15000;
            case 4:
                return 20000;
            case 5:
                return 25000;
            case 6:
                return 30000;
            case 7:
                return 35000;
            case 8:
                return 40000;
            case 9:
                return 45000;
            case 10:
                return 50000;
            case 11:
                return 55000;
            case 12:
                return 60000;
            case 13:
                return 65000;
            case 14:
                return 70000;
            case 15:
                return 75000;
        }
    };

    render() {
        const {t} = this.props;

        return (
            <div className="calculator">
                <div className="left-calc">
                    <div className="icons">
                        <div className="icon">
                            {this.props.language === 'en' || this.props.language === 'pl' ? (
                                <div
                                    className="value">{(((this.state.hr * 0.56))).toFixed(0)} &euro;</div>
                            ) : (
                                <div
                                    className="value">{(((this.state.hr * 0.56) * 9.5)).toFixed(0)} nok</div>
                            )}
                            <img src={calcPeople} alt="calcHR"/>
                            <div className="desc"><Trans i18nKey="translation.hr"/></div>
                        </div>
                        <div className="icon">
                            {this.props.language === 'en' || this.props.language === 'pl' ?
                                (<div
                                    className="value">{((this.state.driver * 0.25)).toFixed(0)} &euro;</div>) :
                                (<div
                                    className="value">{((this.state.driver * 0.25) * 9.5).toFixed(0)} nok</div>)}
                            <img src={calcMoney} alt="calcHR"/>
                            <div className="desc">
                                <Trans i18nKey="translation.driverCostVehicleSalary"/>
                            </div>
                        </div>
                        <div className="icon">
                            <div className="value">{Math.round((this.state.time * 0.5) / 60)} h
                            </div>
                            <img src={calcClockRight} alt="calcHR"/>
                            <div className="desc"><Trans i18nKey="translation.monthlyTimeSpend"/></div>
                        </div>
                    </div>
                    <div className="slider">
                        <Slider defaultValue={this.state.leftValue} min={1} max={15}
                                onChange={this.calculateLeft} tipFormatter={this.formatTooltip}/>
                    </div>
                    <div className="nod">
                        <div>1</div>
                        <div><strong>{t('translation.noDrivers')}</strong></div>
                        <div>150</div>
                    </div>
                </div>
                <div className="right-calc">
                    <div className="icons">
                        <div className="icon">
                            {this.props.language === 'en' || this.props.language === 'pl' ? (<div
                                    className="value">{(this.state.fuel * 0.45).toFixed(0)} &euro;</div>) :
                                (<div
                                    className="value">{((this.state.fuel * 0.45) * 9.5).toFixed(0)} nok</div>)}
                            <img src={calcFuel} alt="calcHR"/>
                            <div className="desc"><Trans i18nKey="translation.fuelConsumption"/></div>
                        </div>
                        <div className="icon">
                            <div className="value">{(this.state.co * 0.21).toFixed(0)}<br/> kg
                                CO<sub>2</sub></div>
                            <img src={calcCo} alt="calcHR"/>
                            <div className="desc"><Trans i18nKey="translation.co2Emission"/></div>
                        </div>
                        <div className="icon">
                            <div className="value">{Math.round((this.state.clock * 0.98) / 60)} h
                            </div>
                            <img src={calcClockLeft} alt="calcHR"/>
                            <div className="desc">{t('translation.timeSpend')}</div>
                        </div>
                    </div>
                    <div className="slider">
                        <Slider defaultValue={this.state.rightValue} min={1} max={15}
                                onChange={this.calculateRight}
                                tipFormatter={this.formatTooltipRight}/>
                    </div>
                    <div className="kpd">
                        <div>5000</div>
                        <div style={{textAlign: 'center'}}><Trans i18nKey="translation.kmsPerDriver"/></div>
                        <div>75000</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Calculator);