import React from 'react';
import "./Precize.scss";
import precizeLeft from "../../../assets/change2.svg";
import LazyLoad from "react-lazyload";
import precizeRight from "../../../assets/precize-right.svg";
import {Trans, useTranslation} from 'react-i18next';


const Precize = props => {

    const {t} = useTranslation();

    return (
        <div className="precize">
            <div className="precize-header">
                {t('translation.bePrecize')}
            </div>
            <div className="body">
                <div className="left-image">
                    <img src={precizeLeft} alt="precize-left"/>
                </div>
                <div className="desc">
                    <LazyLoad offset={-200}>
                        <div className="desc-one slide-in-right">
                            <div style={{marginLeft: "10px"}}>
                                <div style={{marginBottom: "30px", color: "#303D53"}}>
                                    <Trans i18nKey="translation.addDeliveryPoints"/>
                                </div>

                                <div style={{color: "#303D53"}}>
                                    <Trans i18nKey="translation.ifTheSystem"/>
                                </div>
                            </div>
                        </div>
                    </LazyLoad>
                    <LazyLoad offset={-200}>
                        <div className="desc-two slide-in-left">
                            <div style={{marginRight: "10px", color: "#303D53"}}>
                                <Trans i18nKey="translation.adjustShifts"/>
                            </div>
                        </div>
                    </LazyLoad>
                </div>
                <div className="right-image">
                    <div className="optimize-text">{t('translation.optimizeDailyWork')}</div>
                    <img src={precizeRight} alt="precize-right"/>
                </div>
            </div>
        </div>
    );
};

export default Precize;