import React, {useState} from 'react';
import "./MobileOperationView.scss";
import operationIcon from "../../../assets/mobile/operation-icon.svg";
import mapColor from "../../../assets/map-color.png";
import mapDark from "../../../assets/map-dark.png";
import mapMono from "../../../assets/map-mono.png";
import iconDark from "../../../assets/icon-dark.png";
import iconColor from "../../../assets/icon-color.png";
import iconMono from "../../../assets/icon-mono.png";
import mapDesc from "../../../assets/map-desc.svg";
import {Trans, useTranslation} from "react-i18next";

const MobileOperationView = () => {

    const {t} = useTranslation();
    const [mapStyle, setMapStyle] = useState(1);


    const changeMapStyle = value => {
        setMapStyle(value);
    };

    return (
        <>
            <div className="sp slide-in-left">
                <img src={operationIcon}/>
                <div>
                    {t('translation.operationView')}
                </div>
            </div>
            <div className="maps">
                <div className="map-view">
                    {mapStyle === 1 ?
                        <img src={mapColor}
                             alt="map-color"/> : mapStyle === 2 ?
                            <img src={mapDark} alt="map-dark"/> :
                            <img src={mapMono} alt="map-mono"/>}
                    <div className="map-view-details">
                        <div className="map-icons">
                            <div
                                className={mapStyle === 2 ? 'dark heartbeat' : 'dark'}>
                                <img src={iconDark} alt="icon-dark"
                                     onClick={() => changeMapStyle(2)}/>
                            </div>
                            <div
                                className={mapStyle === 1 ? 'color heartbeat' : 'color'}>
                                <img src={iconColor} alt="icon-color"
                                     onClick={() => changeMapStyle(1)}/>
                            </div>
                            <div
                                className={mapStyle === 3 ? 'mono heartbeat' : 'mono'}>
                                <img src={iconMono} alt="icon-mono"
                                     onClick={() => changeMapStyle(3)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="map-desc">
                    <img src={mapDesc} alt="map-desc"/>
                </div>
            </div>
            <div className="desci slide-in-left" style={{margin: '20px'}}>
                <Trans i18nKey="translation.mobile.controlTheSituation"/>
            </div>
        </>
    );
};

export default MobileOperationView;