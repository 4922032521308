import React from 'react';
import {Modal} from "antd";
import "./SuccessMessage.scss";


const SuccesMesage = (props) => {
    return (
        <div className="success-message-container">
            <Modal
                className="success-message-modal"
                visible={props.visible}
                // onOk={this.handleOk}
                onCancel={props.closeModal}
                footer={null}
            >
                <div className="success-body">
                    {props.language === 'en' ? (
                        <>
                            <h4 className="success-heading">Thank you for getting in touch!</h4>
                            <br/>
                            <p className="success-text">We appreciate you contacting OmegoFleet.</p>
                            <p className="success-text">One of our colleagues will get back in touch with you soon!</p>
                            <br/>
                            <br/>
                            <p className="success-text">Have a great day!</p>
                        </>
                    ) : (
                        <>
                            <h4 className="success-heading">Takk for at du tok kontakt!</h4>
                            <br/>
                            <p className="success-text">Vi setter pris på at du har tatt kontakt med OmegoFleet.</p>
                            <p className="success-text">En av våre kollegaer tar kontakt med deg snarest.</p>
                            <br/>
                            <br/>
                            <p className="success-text">Ønsker deg en fortsatt fin dag!</p>
                        </>
                    )}

                </div>
            </Modal>
        </div>
    );
};

export default SuccesMesage;