import React from 'react';
import omegoHeader from "../../assets/omego-fleet.svg";
import language from "../../assets/language.svg";
import {Select} from "antd";
import motorbike from "../../assets/motorbike.svg";
import headerComp from "../../assets/header-comp2.png";
import bus from "../../assets/bus.svg";
import bike from "../../assets/bike.svg";
import "./OmegoHeader.scss";
import i18n from "i18next";
import {Trans, useTranslation} from 'react-i18next';

const {Option} = Select;


function OmegoHeader(props) {
    const {t} = useTranslation();

    const switchLanguage = lang => {
        i18n.changeLanguage(lang);
    };

    const viewElement = () => {
        const element = document.getElementById("goto");
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    };

    return (
        <div className="omego-header">
            <div className="header-part-one">
                <img src={omegoHeader} alt="omego-fleet"/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div className="language">
                        <img src={language} alt="globe" style={{marginTop: '3px'}}/>
                        <Select value={props.language} style={{width: 140, fontSize: '1.2vw', marginTop: '5px'}}
                                onChange={value => props.handleChange(value)}>
                            <Option value="en" onClick={() => switchLanguage('en')}>English</Option>
                            <Option value="no" onClick={() => switchLanguage('no')}>Norsk</Option>
                            <Option value="pl" onClick={() => switchLanguage('pl')}>Polski</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="header-part-two">
                <div className="virtual-coordinator">
                    <h1 className="header" style={{fontSize: '1.7vw'}}>
                        <Trans i18nKey="translation.virtualCoordinator"/>
                    </h1>
                    <div className="body">
                        <Trans i18nKey="translation.inteligentToolbox"/>
                    </div>
                    <div className="read-more-button" onClick={viewElement}>
                        {t('translation.readMore')}
                    </div>
                </div>
                <div className="virtual-images">
                    <img src={motorbike} alt="motorbike"/>
                    <img src={headerComp} alt="computer"/>
                </div>
            </div>
            <div className="header-part-three">
                <img src={bus} alt="bus"/>
                <img src={bike} alt="bike"/>
            </div>
        </div>
    );
}

export default OmegoHeader;