import React, {Component} from 'react';
import "./OperationView.scss";
import LazyLoad from "react-lazyload";
import mapColor from "../../../assets/color.png";
import mapDark from "../../../assets/black.png";
import mapMono from "../../../assets/chrome.png";
import iconChange from "../../../assets/icon-change.svg";
import iconDark from "../../../assets/icon-dark.png";
import iconColor from "../../../assets/icon-color.png";
import iconMono from "../../../assets/icon-mono.png";
import mapDesc from "../../../assets/map-desc.svg";
import {Trans, withTranslation} from "react-i18next";

class OperationView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapStyle: 2,
        }
    }

    changeMapStyle = (value) => {
        this.setState({mapStyle: value});
    };

    render() {
        const {t} = this.props;
        return (
            <div className="operation-view">
                <LazyLoad offset={-200} placeholder={<div
                    style={{color: "white"}}>
                    <div className="header slide-in-left">
                        {t('translation.operationView')}
                    </div>
                    <div className="desc slide-in-left">
                        <Trans i18nKey="translation.controlTheSituation"/>
                    </div>
                </div>}>

                    <div className="header slide-in-left">
                        {t('translation.operationView')}
                    </div>

                    <div className="desc slide-in-left">
                        <Trans i18nKey="translation.controlTheSituation"/>
                    </div>
                </LazyLoad>
                <div className="maps">
                    <div className="map-view">
                        {this.state.mapStyle === 1 ?
                            <img src={mapColor}
                                 alt="map-color"/> : this.state.mapStyle === 2 ?
                                <img src={mapDark} alt="map-dark"/> :
                                <img src={mapMono} alt="map-mono"/>}
                        <div className="map-view-details">
                            <LazyLoad offset={-200} placeholder={
                                <div className="text slide-in-bottom" style={{height: '25%'}}>
                                    <Trans i18nKey="translation.changeMapTypes"/>
                                </div>
                            }>
                                <div className="text slide-in-bottom" style={{height: '25%'}}>
                                    <Trans i18nKey="translation.changeMapTypes"/>
                                </div>
                            </LazyLoad>
                            <div className="map-icons">
                                <div className="change">
                                    <img src={iconChange} alt="icon-change"/>
                                </div>
                                <div
                                    className={this.state.mapStyle === 2 ? 'dark heartbeat' : 'dark'}>
                                    <img src={iconDark} alt="icon-dark"
                                         onClick={() => this.changeMapStyle(2)}/>
                                </div>
                                <div
                                    className={this.state.mapStyle === 1 ? 'color heartbeat' : 'color'}>
                                    <img src={iconColor} alt="icon-color"
                                         onClick={() => this.changeMapStyle(1)}/>
                                </div>
                                <div
                                    className={this.state.mapStyle === 3 ? 'mono heartbeat' : 'mono'}>
                                    <img src={iconMono} alt="icon-mono"
                                         onClick={() => this.changeMapStyle(3)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map-desc">
                        <img src={mapDesc} alt="map-desc"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OperationView);