import React, {useState} from 'react';
import "./MobileCalculators.scss";
import {Progress, Slider} from "antd";
import {Trans, useTranslation} from "react-i18next";
import {formatTooltip} from "../../../utils/funcs/formatTooltip";
import {formatTooltipRight} from "../../../utils/funcs/formatTooltipRight";

const MobileCalculators = () => {
    const {t} = useTranslation();
    const currentLanguage = localStorage.getItem('language');
    const [calculatorState, setCalculatorState] = useState({
        rightMoved: false,
        leftMoved: false,
        rightValue: 0,
        leftValue: 0,
    });
    const [calculatorValueState, setCalculatorValueState] = useState(null);
    const [kilometers, setKilometers] = useState(0);
    const [driversNumber, setDriversNumber] = useState(0);

    const calculateLeft = value => {
        if (value !== undefined) {
            setCalculatorState({leftValue: value, rightMoved: false, leftMoved: true});
            switch (value.toString()) {
                case '1':
                    setCalculatorValueState({hr: 1 * 5600, driver: 1 * 9000, driversNumber: 1, time: 1 * 110});
                    return;
                case '2':
                    setCalculatorValueState({hr: 1 * 5600, driver: 2 * 9000, driversNumber: 2, time: 2 * 110});
                    return;
                case '3':
                    setCalculatorValueState({hr: 1 * 5600, driver: 3 * 9000, driversNumber: 3, time: 3 * 110});
                    return;
                case '4':
                    setCalculatorValueState({hr: 1 * 5600, driver: 4 * 9000, driversNumber: 4, time: 4 * 110});
                    return;
                case '5':
                    setCalculatorValueState({hr: 1 * 5600, driver: 5 * 9000, driversNumber: 5, time: 5 * 110});
                    return;
                case '6':
                    setCalculatorValueState({hr: 1 * 5600, driver: 10 * 9000, driversNumber: 10, time: 10 * 110});
                    return;
                case '7':
                    setCalculatorValueState({hr: 1 * 5600, driver: 15 * 9000, driversNumber: 15, time: 15 * 110});
                    return;
                case '8':
                    setCalculatorValueState({hr: 1 * 5600, driver: 20 * 9000, driversNumber: 20, time: 20 * 110});
                    return;
                case '9':
                    setCalculatorValueState({hr: 1 * 5600, driver: 25 * 9000, driversNumber: 25, time: 25 * 110});
                    return;
                case '10':
                    setCalculatorValueState({hr: 2 * 5600, driver: 30 * 9000, driversNumber: 30, time: 30 * 110});
                    return;
                case '11':
                    setCalculatorValueState({hr: 2 * 5600, driver: 50 * 9000, driversNumber: 50, time: 50 * 110});
                    return;
                case '12':
                    setCalculatorValueState({hr: (3 * 5600), driver: 75 * 9000, driversNumber: 75, time: 75 * 110});
                    return;
                case '13':
                    setCalculatorValueState({hr: 4 * 5600, driver: 100 * 9000, driversNumber: 100, time: 100 * 110});
                    return;
                case '14':
                    setCalculatorValueState({hr: 4 * 5600, driver: 125 * 9000, driversNumber: 125, time: 125 * 110});
                    return;
                case '15':
                    setCalculatorValueState({hr: 5 * 5600, driver: 150 * 9000, driversNumber: 150, time: 150 * 110});
                    return;
            }

            return;
        }

        setCalculatorState({rightMoved: false, leftMoved: true});

        switch (calculatorState.leftValue.toString()) {
            case '1':
                setCalculatorValueState({hr: 1 * 5600, driver: 1 * 9000, driversNumber: 1, time: 1 * 110});
                return;
            case '2':
                setCalculatorValueState({hr: 1 * 5600, driver: 2 * 9000, driversNumber: 2, time: 2 * 110});
                return;
            case '3':
                setCalculatorValueState({hr: 1 * 5600, driver: 3 * 9000, driversNumber: 3, time: 3 * 110});
                return;
            case '4':
                setCalculatorValueState({hr: 1 * 5600, driver: 4 * 9000, driversNumber: 4, time: 4 * 110});
                return;
            case '5':
                setCalculatorValueState({hr: 1 * 5600, driver: 5 * 9000, driversNumber: 5, time: 5 * 110});
                return;
            case '6':
                setCalculatorValueState({hr: 1 * 5600, driver: 10 * 9000, driversNumber: 10, time: 10 * 110});
                return;
            case '7':
                setCalculatorValueState({hr: 1 * 5600, driver: 15 * 9000, driversNumber: 15, time: 15 * 110});
                return;
            case '8':
                setCalculatorValueState({hr: 1 * 5600, driver: 20 * 9000, driversNumber: 20, time: 20 * 110});
                return;
            case '9':
                setCalculatorValueState({hr: 1 * 5600, driver: 25 * 9000, driversNumber: 25, time: 25 * 110});
                return;
            case '10':
                setCalculatorValueState({hr: 2 * 5600, driver: 30 * 9000, driversNumber: 30, time: 30 * 110});
                return;
            case '11':
                setCalculatorValueState({hr: 2 * 5600, driver: 50 * 9000, driversNumber: 50, time: 50 * 110});
                return;
            case '12':
                setCalculatorValueState({hr: (3 * 5600), driver: 75 * 9000, driversNumber: 75, time: 75 * 110});
                return;
            case '13':
                setCalculatorValueState({hr: 4 * 5600, driver: 100 * 9000, driversNumber: 100, time: 100 * 110});
                return;
            case '14':
                setCalculatorValueState({hr: 4 * 5600, driver: 125 * 9000, driversNumber: 125, time: 125 * 110});
                return;
            case '15':
                setCalculatorValueState({hr: 5 * 5600, driver: 150 * 9000, driversNumber: 150, time: 150 * 110});
                return;
        }
    };

    const calculateRight = value => {
        if (value !== undefined) {
            setCalculatorState({rightValue: value, rightMoved: true, leftMoved: false});
            switch (value.toString()) {
                case '15':
                    setCalculatorValueState({
                        fuel: 75000 * 0.15 * 1.4,
                        co: 2640 * (75000 * 0.15) * 0.001,
                        kilometers: 75000,
                        clock: 5250 * 2
                    });
                    return;
                case '14':
                    setCalculatorValueState({
                        fuel: 70000 * 0.15 * 1.4,
                        co: 2640 * (70000 * 0.15) * 0.001,
                        kilometers: 70000,
                        clock: 4375 * 2
                    });
                    return;
                case '13':
                    setCalculatorValueState({
                        fuel: 65000 * 0.15 * 1.4,
                        co: 2640 * (65000 * 0.15) * 0.001,
                        kilometers: 65000,
                        clock: 3500 * 2
                    });
                    return;
                case '12':
                    setCalculatorValueState({
                        fuel: 60000 * 0.15 * 1.4,
                        co: 2640 * (60000 * 0.15) * 0.001,
                        kilometers: 60000,
                        clock: 2625 * 2
                    });
                    return;
                case '11':
                    setCalculatorValueState({
                        fuel: 55000 * 0.15 * 1.4,
                        co: 2640 * (55000 * 0.15) * 0.001,
                        kilometers: 55000,
                        clock: 1750 * 2
                    });
                    return;
                case '10':
                    setCalculatorValueState({
                        fuel: 50000 * 0.15 * 1.4,
                        co: 2640 * (50000 * 0.15) * 0.001,
                        kilometers: 50000,
                        clock: 1050 * 2
                    });
                    return;
                case '9':
                    setCalculatorValueState({
                        fuel: 45000 * 0.15 * 1.4,
                        co: 2640 * (45000 * 0.15) * 0.001,
                        kilometers: 45000,
                        clock: 875 * 2
                    });
                    return;
                case '8':
                    setCalculatorValueState({
                        fuel: 40000 * 0.15 * 1.4,
                        co: 2640 * (40000 * 0.15) * 0.001,
                        kilometers: 40000,
                        clock: 700 * 2
                    });
                    return;
                case '7':
                    setCalculatorValueState({
                        fuel: 35000 * 0.15 * 1.4,
                        co: 2640 * (35000 * 0.15) * 0.001,
                        kilometers: 35000,
                        clock: 525 * 2
                    });
                    return;
                case '6':
                    setCalculatorValueState({
                        fuel: 30000 * 0.15 * 1.4,
                        co: 2640 * (30000 * 0.15) * 0.001,
                        kilometers: 30000,
                        clock: 350 * 2
                    });
                    return;
                case '5':
                    setCalculatorValueState({
                        fuel: 25000 * 0.15 * 1.4,
                        co: 2640 * (25000 * 0.15) * 0.001,
                        kilometers: 25000,
                        clock: 175 * 2
                    });
                    return;
                case '4':
                    setCalculatorValueState({
                        fuel: 20000 * 0.15 * 1.4,
                        co: 2640 * (20000 * 0.15) * 0.001,
                        kilometers: 20000,
                        clock: 140 * 2
                    });
                    return;
                case '3':
                    setCalculatorValueState({
                        fuel: 15000 * 0.15 * 1.4,
                        co: 2640 * (15000 * 0.15) * 0.001,
                        kilometers: 15000,
                        clock: 105 * 2
                    });
                    return;
                case '2':
                    setCalculatorValueState({
                        fuel: 10000 * 0.15 * 1.4,
                        co: 2640 * (10000 * 0.15) * 0.001,
                        kilometers: 10000,
                        clock: 70 * 2
                    });
                    return;
                case '1':
                    setCalculatorValueState({
                        fuel: 5000 * 0.15 * 1.4,
                        co: 2640 * (5000 * 0.15) * 0.001,
                        kilometers: 5000,
                        clock: 35 * 2
                    });
                    return;
            }

            return;
        }

        setCalculatorState({rightMoved: true, leftMoved: false});

        switch (calculatorState.rightValue.toString()) {
            case '15':
                setCalculatorValueState({
                    fuel: 75000 * 0.15 * 1.4,
                    co: 2640 * (75000 * 0.15) * 0.001,
                    kilometers: 75000,
                    clock: 5250 * 2
                });
                return;
            case '14':
                setCalculatorValueState({
                    fuel: 70000 * 0.15 * 1.4,
                    co: 2640 * (70000 * 0.15) * 0.001,
                    kilometers: 70000,
                    clock: 4375 * 2
                });
                return;
            case '13':
                setCalculatorValueState({
                    fuel: 65000 * 0.15 * 1.4,
                    co: 2640 * (65000 * 0.15) * 0.001,
                    kilometers: 65000,
                    clock: 3500 * 2
                });
                return;
            case '12':
                setCalculatorValueState({
                    fuel: 60000 * 0.15 * 1.4,
                    co: 2640 * (60000 * 0.15) * 0.001,
                    kilometers: 60000,
                    clock: 2625 * 2
                });
                return;
            case '11':
                setCalculatorValueState({
                    fuel: 55000 * 0.15 * 1.4,
                    co: 2640 * (55000 * 0.15) * 0.001,
                    kilometers: 55000,
                    clock: 1750 * 2
                });
                return;
            case '10':
                setCalculatorValueState({
                    fuel: 50000 * 0.15 * 1.4,
                    co: 2640 * (50000 * 0.15) * 0.001,
                    kilometers: 50000,
                    clock: 1050 * 2
                });
                return;
            case '9':
                setCalculatorValueState({
                    fuel: 45000 * 0.15 * 1.4,
                    co: 2640 * (45000 * 0.15) * 0.001,
                    kilometers: 45000,
                    clock: 875 * 2
                });
                return;
            case '8':
                setCalculatorValueState({
                    fuel: 40000 * 0.15 * 1.4,
                    co: 2640 * (40000 * 0.15) * 0.001,
                    kilometers: 40000,
                    clock: 700 * 2
                });
                return;
            case '7':
                setCalculatorValueState({
                    fuel: 35000 * 0.15 * 1.4,
                    co: 2640 * (35000 * 0.15) * 0.001,
                    kilometers: 35000,
                    clock: 525 * 2
                });
                return;
            case '6':
                setCalculatorValueState({
                    fuel: 30000 * 0.15 * 1.4,
                    co: 2640 * (30000 * 0.15) * 0.001,
                    kilometers: 30000,
                    clock: 350 * 2
                });
                return;
            case '5':
                setCalculatorValueState({
                    fuel: 25000 * 0.15 * 1.4,
                    co: 2640 * (25000 * 0.15) * 0.001,
                    kilometers: 25000,
                    clock: 175 * 2
                });
                return;
            case '4':
                setCalculatorValueState({
                    fuel: 20000 * 0.15 * 1.4,
                    co: 2640 * (20000 * 0.15) * 0.001,
                    kilometers: 20000,
                    clock: 140 * 2
                });
                return;
            case '3':
                setCalculatorValueState({
                    fuel: 15000 * 0.15 * 1.4,
                    co: 2640 * (15000 * 0.15) * 0.001,
                    kilometers: 15000,
                    clock: 105 * 2
                });
                return;
            case '2':
                setCalculatorValueState({
                    fuel: 10000 * 0.15 * 1.4,
                    co: 2640 * (10000 * 0.15) * 0.001,
                    kilometers: 10000,
                    clock: 70 * 2
                });
                return;
            case '1':
                setCalculatorValueState({
                    fuel: 5000 * 0.15 * 1.4,
                    co: 2640 * (5000 * 0.15) * 0.001,
                    kilometers: 5000,
                    clock: 35 * 2
                });
                return;
        }
    };

    const getPercent = () => {
        if (calculatorState.rightMoved) {
            return calculatorValueState?.kilometers / 750;
        }

        return calculatorValueState?.driversNumber * 2/ 1;
    };

    const getPercentSecond = () => {
        if (calculatorState.rightMoved) {
            return calculatorValueState?.kilometers / 750;
        }

        return (calculatorValueState?.driversNumber * 2) / 1;
    };

    const getLabel = () => {
        if (calculatorState.leftValue == 0 && calculatorState.rightValue == 0) {
            return '?'
        }

        if (calculatorState.leftMoved && currentLanguage === 'en') {
            return `${Math.round(calculatorValueState.hr * 0.56)} \u20ac 
            HR and Administration`
        }

        if (calculatorState.leftMoved && currentLanguage === 'pl') {
            return `${Math.round(calculatorValueState.hr * 0.56)} \u20ac \ HR i Administracja`
        }

        if (calculatorState.leftMoved && currentLanguage === 'no') {
            return `${Math.round((calculatorValueState.hr * 0.56) * 9.5)} nok
            HR og administrasjon`
        }

        if (currentLanguage === 'en') {
            return `${(calculatorValueState.fuel * 0.45).toFixed(0)} \u20ac
            Fuel Consumption`
        }

        if (currentLanguage === 'pl') {
            return `${(calculatorValueState.fuel * 0.45).toFixed(0)} \u20ac
            Zużycie paliwa`
        }

        if (currentLanguage === 'no') {
            return `${((calculatorValueState.fuel * 0.45) * 9.5).toFixed(0)} nok
            Drivstofforbruk`
        }
    };

    const getLabelSecond = () => {
        if (calculatorState.leftValue == 0 && calculatorState.rightValue == 0) {
            return '?'
        }

        if (calculatorState.leftMoved && currentLanguage === 'en') {
            return `${((calculatorValueState.driver * 0.25)).toFixed(0)} \u20ac
            Drivers and vehicle cost salary`
        }

        if (calculatorState.leftMoved && currentLanguage === 'pl') {
            return `${((calculatorValueState.driver * 0.25)).toFixed(0)} \u20ac \n
             Koszt pracowników i pojazdów`
        }

        if (calculatorState.leftMoved && currentLanguage === 'no') {
            return `${((calculatorValueState.driver * 0.25) * 9.5).toFixed(0)} nok
            Sjåfør lønn og bilkostnader`
        }

        if (currentLanguage === 'en') {
            return `${(calculatorValueState.co * 0.21).toFixed(0)} kgCO2
           CO2 emission`
        }

        if (currentLanguage === 'pl') {
            return `${(calculatorValueState.co * 0.21).toFixed(0)} kgCO2
           emisja CO2`
        }

        if (currentLanguage === 'no') {
            return `${(calculatorValueState.co * 0.21).toFixed(0)} kgCO2
            CO2-utslipp`
        }
    };

    const getLabelThird = () => {
        if (calculatorState.leftValue == 0 && calculatorState.rightValue == 0) {
            return '?'
        }

        if (calculatorState.leftMoved && currentLanguage === 'en') {
            return `${((calculatorValueState.time * 0.5) / 60).toFixed(1)} h 
            Monthly Time spent on shift planning`
        }

        if (calculatorState.leftMoved && currentLanguage === 'pl') {
            return `${((calculatorValueState.time * 0.5) / 60).toFixed(1)} h 
            Czas poświęcony na planowanie zmian`
        }

        if (calculatorState.leftMoved && currentLanguage !== 'en') {
            return `${((calculatorValueState.time * 0.5) / 60).toFixed(1)} h 
            Månedlig tidsforbruk på skiftplan-leggging`
        }

        if (currentLanguage === 'en') {
            return `${((calculatorValueState.clock * 0.98) / 60).toFixed(1)} h
            Time spent on Route Planning`
        }

        if (currentLanguage === 'pl') {
            return `${((calculatorValueState.clock * 0.98) / 60).toFixed(1)} h
            Czas poświęcone na planowanie tras`
        }

        if (currentLanguage !== 'en') {
            return `${((calculatorValueState.clock * 0.98) / 60).toFixed(1)} h
            Tidsbruk på ruteplanlegging`
        }
    };

    return (
        <div className="mobile-calculators">
            <div className="calculators">
                <div className="first-slider">
                    <Slider defaultValue={calculatorState.leftValue} min={1} max={15} tipFormatter={formatTooltip}
                            onChange={calculateLeft}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        fontSize: '3vw'
                    }}>
                        <div>1</div>
                        <div style={{fontWeight: 'bold'}}>{t('translation.noDrivers')}</div>
                        <div>150</div>
                    </div>
                </div>
                <div className="second-slider">
                    <Slider defaultValue={calculatorState.rightValue} min={1} max={15}
                            tipFormatter={formatTooltipRight} onChange={calculateRight}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        fontSize: '3vw'
                    }}>
                        <div>5000</div>
                        <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                            <Trans i18nKey="translation.mobile.kmsPerDriver"/>
                        </div>

                        <div>75000</div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: 50}}>
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div className="green">
                            <Progress type="circle" percent={getPercent()} format={percent => getLabel()}
                                      strokeWidth={2}/>
                        </div>
                        <div className="blue">
                            <Progress type="circle" percent={getPercentSecond()}
                                      format={percent => getLabelSecond()} strokeWidth={2} strokeColor={{
                                '0%': '#1B51DC',
                                '100%': '#1B51DC',
                            }} width={100}/>
                        </div>
                    </div>
                    <div className="orange">
                        <Progress type="circle" percent={getPercent()} format={percent => getLabelThird()}
                                  strokeWidth={2} strokeColor={{
                            '0%': '#EE5D2E',
                            '100%': '#EE5D2E',
                        }} width={100}/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MobileCalculators;