import React, {Component} from "react";
import "./MobilePrivacyPolicy.scss";
import MobileTermsHeader from "../MobileTermsHeader/MobileTermsHeader";
import MobileFooter from "../MobileFooter/MobileFooter";
import twitter from "../../../assets/twitter.svg";
import mediumm from "../../../assets/medium.svg";
import linked from "../../../assets/linked.svg";

class MobilePrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="mobile-privacy-policy">
                <MobileTermsHeader/>

                <div className="mobile-privacy-policy-content">
                    <h1 className="mobile-pp-title">Privacy Policy of the Omego Fleet Application / Web Platform
                        (Omego)</h1>
                    <div>
                        <p>The company Delta Media AS (a Norwegian private limited liability company with VAT nr.
                            916747330) an owner and operator (hereinafter referred to as the „Owner” or
                            „Administrator”) of the Omego Fleet application (hereinafter “OMEGO”) referred to as the
                            “Application”, to be operated on mobile devices (with Android, IOS or Windows Phone
                            system), as well as web browsers, is also the Controller of personal data thereof. The
                            Administrator stores, processes and uses all personal data submitted by the OMEGO
                            Application Users in accordance with the provisions of Norwegian law.
                            The Administrator undertakes to use appropriate tools, in particular to install SSL
                            certificates, encrypt passwords in the database, conduct periodic security audits of the
                            system and exercise due diligence in order to ensure that personal data and address data
                            as well as the privacy of the OMEGO Application Users will be adequate protected in
                            accordance with the applicable laws and regulations. Upon Registration, each User
                            consents to the storing of their personal data and address data in the Application as
                            well as to the storing, processing and using the above data to the extent specified by
                            the Owner. Each User is entitled and allowed to inspect, amend, update, and delete data
                            provided during the registration process at any time.</p>
                    </div>


                    <div className="section" id="general-provisions">
                        <h3 className="section-header">§ 1.General provisions</h3>
                        <ol className="mobile-privacy-policy-content-order-list">
                            <li>
                                In order to access the Application, and thus render services by electronic means,
                                the User must be registered providing certain personal data. Accepting the
                                Regulations and this Privacy Policy entitles the Administrator to collect and
                                process the following data:
                                <ul>
                                    <li>e-mail address,</li>
                                    <li>password, i.e. a series of characters (numbers, letters, special
                                        characters), freely chosen and provided by the User, invisible and
                                        encrypted,
                                    </li>
                                    <li>company registration data.</li>
                                </ul>
                            </li>

                            <li>
                                If personal data and address data referred to in § 1(1) are not provided, creating a
                                User Account will be impossible.
                            </li>
                        </ol>
                    </div>

                    <div className="section">
                        <h3 className="section-header" id="processing-data">§ 2. The processing of personal and
                            non-personal data in the
                            Application</h3>
                        <ol className="mobile-privacy-policy-content-order-list">

                            <li>
                                Data referred to in § 1(2) are collected in the process of Registration so that the
                                Application Owner can:

                                <ul>
                                    <li>
                                        provide services accessible through the Application, such as:
                                        <ul>
                                            <li>maintaining the User Account, providing the Users with access to the
                                                Account full functionality of the Application,
                                            </li>
                                            <li>sharing commercial offers through the Application.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        fulfil its legal and accounting obligations related to the provision of
                                        services through the Application.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                Your personal information will help us keep you informed about our new products,
                                software updates and upcoming events.
                            </li>

                            <li>
                                Your data is processed under Norwegian national law in accordance with the
                                Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April
                                2016, on the protection of individuals with regards to the processing of personal
                                data and on the free the flow of such data and the repeal of Directive 95/46 / EC
                                (general regulation on data protection).
                            </li>

                            <li>
                                After getting your prior consent we may use your personal data to create, develop,
                                provide and improve our products and services, also within our advertising material.
                            </li>

                            <li>
                                Some anonymized and non-identifiable of the personal information, such as the year
                                of birth may be used to determine the demographic of our account holders. An example
                                will be something as simple as your age based on date of birth.
                            </li>

                            <li>
                                We have to use your personal information to keep you updated in case of important
                                notifications, especially with regards to changes in our terms and policies. You may
                                not be able to stop receiving such notifications.
                            </li>

                            <li>
                                The application/system will also collect information that cannot be traced back to a
                                specific person or user.
                            </li>

                            <li>
                                Some of these data is aggregated and used to provide targeted information to our
                                customers. Aggregated data is considered non-personal information in the context of
                                this Privacy Policy because they do not allow the identification of specific users..
                                We will collect and store information about how our customers use our services,
                                especially with regards to searches. We will also collect information about how you
                                use your device and programs to help our developers improve their programs.
                            </li>
                        </ol>
                    </div>

                    <div className="section">
                        <h3 className="section-header" id="changes-to-personal-data">§ 3. Changes to personal
                            data</h3>
                        <ol className="mobile-privacy-policy-content-order-list">

                            <li>
                                Each User is entitled and allowed to inspect, amend, update, and delete data
                                provided after the Registration process at any time.
                            </li>

                            <li>
                                The Application User is obliged to update their data referred to in § 1(2)
                                immediately after any change thereto, not later than within 3 (three) working days
                                from the date of the change.
                            </li>

                            <li>
                                Should the User fail to update data referred to in § 3(2), the Owner may suspend the
                                User's Account until the update is provided.
                            </li>

                            <li>
                                The User is entitled to inspect, amend, change and update data referred to in § 3(2)
                                only after logging into their Account.
                            </li>

                            <li>
                                The User sends a written request asking to remove data from the Account to the
                                Administrator, to the following e-mail: address: support@omegofleet.com.
                            </li>
                        </ol>
                    </div>


                    <div className="section">
                        <h3 className="section-header" id="limitation-of-ability">§ 4. Limitation of ability</h3>
                        <ol className="mobile-privacy-policy-content-order-list">
                            <li>
                                If the Administrator learns that the User makes use of the service provided
                                electronically in a manner inconsistent either with the Regulations governing the
                                OMEGO Application or with the applicable provisions of law (unauthorized use), the
                                Administrator may process the User's personal data and address data to the extent
                                necessary to determine the liability of the User. The Administrator shall record
                                this fact for evidential purposes.
                            </li>

                            <li>
                                The User, in accordance with the Regulations governing the OMEGO Application,
                                undertakes to prevent third parties from accessing the Application. The User is
                                solely responsible for confidentiality and security of their account. The User is
                                also obliged to immediately inform the Administrator of any unauthorized use of
                                their Account in the Application by third parties.
                            </li>

                            <li>
                                The Administrator is not responsible for the content of data provided by the Users.
                            </li>

                            <li>
                                The Administrator is not being responsible for processing data by third parties who
                                place external links in the Application, taking the Users to a different website.
                            </li>

                            <li>
                                The provisions of this Privacy Policy do not apply to entrepreneurs and
                                individuals/entities whose data are generally available.
                            </li>

                            <li>
                                Sometimes with your prior consent OMEGO may provide personal information to
                                strategic partners that work with OMEGO to provide services or products that help
                                OMEGO bring these products/services to the market. If you agree, when using OMEGO,
                                you can give us permission to exchange the information you provide as a
                                user/company. Personal information will only be shared by OMEGO to provide
                                products/services and for advertising and improve. Personal information will never
                                be shared with third parties for marketing purposes. We may disclose your personal
                                information if we believe it is necessary or appropriate in the context of national
                                security, law enforcement or other matters of public interest under applicable law.
                                We may also disclose your information if we determine that it is reasonably
                                necessary to enforce our terms and conditions or protect our operations or users.
                                Moreover, we may in a reorganization, merger, or sale, transfer any personal
                                information we collect to the third party.
                            </li>
                        </ol>
                    </div>


                    <div className="section" id="cookies">
                        <h3 className="section-header">§ 5. Cookies</h3>
                        <ol className="mobile-privacy-policy-content-order-list">
                            <li>
                                The Application uses cookies, i.e. small-sized files containing the Owner's
                                information stored on the User's mobile devices/web browsers to make it easier for
                                the Users to navigate the site and perform certain functions.
                            </li>
                        </ol>
                    </div>

                    <div className="company">
                        <div>Delta Media AS, Østensjøveien 43, 0667 Oslo</div>
                        <div>contact@omegofleet.com</div>
                        <div className="dot"></div>
                        <div>+47 944 784 64</div>
                    </div>
                    <div className="media">
                        <img src={twitter}/>
                        <img src={mediumm}/>
                        <img src={linked}/>
                    </div>

                </div>
                <MobileFooter/>
            </div>
        )
    }
}

export default MobilePrivacyPolicy;
