import React from 'react';
import "./Reminders.scss";
import remindersIcon from "../../../assets/reminders-icon.svg";
import reminders from "../../../assets/reminders.svg";
import LazyLoad from "react-lazyload";
import {Trans, useTranslation} from 'react-i18next';

const Reminders = props => {
    const {t} = useTranslation();

    return (
        <div className="reminders">
            <div className="reminders-header">
                <img src={remindersIcon} alt="reminders-icon"/>
                {t('translation.reminders')}
            </div>
            <div className="time-one">
                +30 min
            </div>
            <div className="reminders-image">
                <img src={reminders} alt="reminders"/>
                <div className="text">
                    + 15 min
                </div>
            </div>
            <div className="time-two">
                +60 min
            </div>
            <LazyLoad offset={-200}
                      placeholder={<div className="reminders-desc" style={{color: "white"}}>
                          <Trans i18nKey="translation.whenYouAreBusy"/>
                      </div>}>

                <div className="reminders-desc scale-in-center">
                    <Trans i18nKey="translation.whenYouAreBusy"/>
                </div>
            </LazyLoad>
        </div>
    );
};

export default Reminders;